import { en } from "vuetify/lib/locale";

export default {
  ...en,

  languageName: "EN - English",
  languageFlag: "gb",

  //Erros
  errorCreditNotesIssue: "No more credit notes can be issued for the Invoice", //Não é possível emitir mais notas de crédito para a Fatura
  errorGettingCountries: "Error getting Countries", //"Erro a obter Países"
  errorGettingCustomerDetail: "Getting the Customer Detail",
  errorIdAvailableCampaigns: "Could not identify available campaigns", //Não foi possível identificar Campanhas Disponíveis
  errorIdAvailableVouchers: "No Available Vouchers could be identified.", //Não foi possível identificar Vales Disponíveis.
  errorInfoEmployee: "Error obtaining information from Employee ", //Erro a obter informação do Empregado
  errorInvalidLoyaltyCard: "Invalid Loyalty Card", //Cartão de Fidelização Inválido
  errorNoSaleItems: "There are no items in the Sale", //Não há artigos na Venda
  errorRegisterBudget: "It was not possible to register the budget.", //Não foi possível registar o Orçamento.
  errorRegisterClient: "It was not possible to register client.", //Não foi possível registar o Cliente.
  errorRegisterSale: "It was not possible to register sale.", //Não foi possível registar a venda.
  errorRegisterSessionMovement: "It was not possible to register the Session Movement.", //Não foi possível registar o Movimento de Sessão.
  errorSearchValueNotIndicated: "No value was indicated for search.", //Não foi indicado um valor para pesquisa.
  errorStoreZone: "Error on getting store zones", // Erro a obter Zonas da Loja
  errorValidatingCredentials: "It was not possible to validate the Employee and password indicated. ", //Não foi possível validar o Empregado e palavra-passe indicados.
  itemNotFoundMessage: "Error adding item to sale. Article not found.", //Erro ao Adicionar Artigo à Venda. Artigo não encontrado.
  catalogSearchResults: {
    errorIdArticles: "It was not possible to identify articles according to the survey.", //Não foi possível identificar Artigos de acordo com a pesquisa.
    errorNoSearch: "No search value was indicated.", //Não foi indicado um valor para pesquisa.
  },
  errorStockExit: "Error getting justified exit.",
  notFoundStockExit: "Justified Exit not found.",
  invalidItemStockExit: "Unsynchronized items were found in justified exit.",
  errorCancelDocument: "It is not possible to cancel the document",
  errorItemToReturn: "Items cannot be added to a Return.",
  errorItemInfoExtra: "Sale without Items does not allow adding Informative/Extra items.",

  //Legendas, Botões e Opcões
  add: "Add", //Adicionar
  addressClient: "Address", //Endereço
  backoffice: "Backoffice",
  billingData: "Billing data", //Dados faturação
  campaigns: "Campaigns", //Campanhas
  cancel: "Cancel", //Cancelar
  cancelAndPrint: "Suspend & Print", //Suspender e Imprimir
  cancelDocument: "Cancellation", //Cancelar
  fromStockExit: "Sale from Justified Exit",
  cashDrawers: "Cash Drawers", //Caixas
  catalog: "Catalog", //Catálogo
  change: "Change", //Troco
  clean: "Clean", //Limpar
  client: "Client",
  close: "Close", //Fechar
  code: "Code", //Código
  consultSales: "Consult sales", //Consulta de Vendas
  consultSalesDescription: "Consult emitted sales documents", //Consulte documentos de venda emitidos
  consultSuspended: "Consult suspended documents", //Consulte documentos em suspenso
  country: "Country", //País
  customerLoyaltyCard: "Loyalty card", //Cartão de Fidelização
  customerMobileTelephone: "Mobile No.", //Nr. Telemóvel
  customerSearchMessage: "Search by Loyalty Card, TIN or Mobile Number", //Pesquise pelo Cartão de Fidelização, NIF ou Número de Telemóvel
  date: "Date",
  discount: "Discount", //Desconto
  discountShort: "Disc.%", //Desc.%
  discountType: "Discount type", //Tipo Desconto
  download: "Download",
  email: "E-mail",
  end: "Finish", //Finalizar
  finalClient: "Final Client", //Cliente final
  globalDiscount: "Global Discount", //Desconto Global
  goBackToLogin: "Back to login", //Voltar para o login
  identifier: "Identifier", //Identificador
  location: "Location", //Localidade
  logout: "LogOut",
  loyalty: "Loyalty campaigns", //Campanhas Fidelização
  loyaltyCardVoucherNumberMessage: "Loyalty Card/Voucher number", //Cartão Fidelização/Nr. Vale
  moneyRemaining: "Remaining", //Faltam
  more: "More", //Mais
  name: "Name", //Nome
  newClient: "New Client", //Novo Cliente
  numberOfCopies: "Number of copies", //Quantidade Impressão
  obs: "Observations", //Observações
  offerDocument: "Gift card", //Talão Oferta
  ok: "Ok", //Ok
  open: "Open", //Abrir
  openCashDrawer: "Open drawer", //Abrir Caixa
  password: "Password", //Palavra-passe
  pattyCash: "Patty cash", //Fundo de Maneio
  pay: "Pay", //Pagar
  payment: "Payment", //Pagamento
  paymentMethod: "Payment Method", //Método de Pagamento
  paymentMethods: "Payment methods", //Formas de Pagamento
  percentage: "Percentage", //Percentagem
  points: "Points", //Pontos
  postalCode: "Postal Code", //Código Postal
  print: "Print", //Imprimir
  quantity: "Quantity", //Quantidade
  reports: "Reports", //Relatórios
  resumeSale: "Resume Sale", //Retomar ??Retomar venda
  return: "Return", //Devolver
  returnDocument: "Return", //Devolução
  sale: "Sale", //Venda
  saleDocumentDate: "Document Date", //Data Documento ??Data do Documento
  sales: "Sales", //Vendas
  salesDescription: "Create new sales documents", //Crie novos documentos de venda
  salesmenName: "Attended by", //Atendido por
  salesmenSessionClose: "Session Close", //Fechar Sessão
  salesmenSessionOpen: "Session Open", //Abrir Sessão
  salesSub: "View sales totals, VAT totals and payment methods", //Visão dos totais de vendas, totais de IVA e formas de pagamento
  save: "Save", //Guardar
  savedSales: "No Suspended Sales", //Sem Vendas Suspensas
  search: "Search", //Pesquisar
  searchDocument: "Search by Document Number", //Pesquisar Nr. Documento
  selectDate: "Select Date", //Selecionar Data
  selectSalesman: "Select Salesman", //Selecionar Empregado
  sessions: "Sessions", //Sessões
  sessionsProcedure: "Proceed to open and close sessions", //Proceda à Abertura e Fecho de Sessões:
  start: "Start", //Início
  submit: "Submit", // Submeter
  suspendedSales: "Suspended Sales", //Vendas Suspensas
  taxNumber: "TIN", //NIF
  total: "Total", //Total
  unitPrice: "Unit Price", //Preço Unitário
  updatePassword: "Update Password", //Atualizar Password
  validate: "Validate", //Validar
  valuePay: "Value", //Valor
  voucherCode: "Voucher Code", //Código do Vale

  //Mensagens de Feedback e Confirmação
  appliedVatExempt: "Vat Exempt Active", //IVA Isento Aplicado
  applyVatExempt: "Apply Vat Exempt", //Aplicar Isenção IVA
  cashDrawersProcedure: "Proceed to open and close cash drawers", //Proceda à Abertura e Fecho de Caixas
  confirmConfirmCancelDocumentMessage: "This is an irreversible operation.", //Esta é uma operação irreversível.
  confirmConfirmCancelDocumentTitle: "Are you sure you want to Cancel the Sale?", //Tem a certeza que pretende Anular a Venda?
  confirmLogout: "Confirm Logout?", //Encerrar a Sessão?
  msgAreYouSure: "Are you sure?", //Tem a certeza?
  msgAreYouSureRemoveItems: "Are you sure you want to remove all the items from the sale?", //Tem a certeza que pretende remover todos os Artigos da Venda?
  msgAreYouSureSuspendSale: "Are you sure you want to Suspend the Sale?", //Tem a certeza que pretende Suspender a Venda?
  msgCheckInstructions: "Check the instructions on the payment terminal", //Verifique as instruções no terminal de pagamento
  msgCloseSessionSuccess: "Successful closing of session.", //Fecho de Sessão realizada com Sucesso.
  msgContentNotAvailable: "Content not available at the moment.", //Conteúdo não disponível de momento.
  msgDetailsInvoice: "Enter your details if you want an invoice with tax details or click on Payment to proceed", //Introduza os seus dados se pretender fatura com dados fiscais ou clique em Pagamento para avançar
  msgExpiredVoucher: "The Refund Voucher expired on ", //O Talão de Reembolso expirou em
  msgInvalidSlip: "Invalid reimbursement slip identifier.", //Identificador do Talão de Reembolso inválido.
  msgOpenEmployeeSessions: "It is necessary to open Employee Sessions", //É necessário proceder à abertura de Sessões de Empregados
  msgOpenSessionSuccess: "Successful opening of session.", //Abertura de Sessão realizada com Sucesso.
  msgSuspendSaleAlert: "Do you want to suspend this sale? If you don't, you'll lose the items in the sale!", //Quer suspender esta venda? Se não suspender, perderá os artigos da venda!
  msgUsedVoucher: "The Refund Slip has already been used.", //O Talão de Reembolso já foi utilizado.
  msgCancelDocument: "Cancellation of the Document successfully carried out.",
  pageNotFound: "Page not found", //Página não encontrada
  removeVatExempt: "Remove Vat Exempt", //Remover Inseção IVA
  startPurchase: "Start your purchase", //Inicie a sua compra
  tryAgain: "Please try again.", //Por favor tente novamente
  warningNoPermissionsTo: "No permissions to access to '", //Sem Permissões para aceder a '
  welcome: "Welcome", //Bem-Vindo
  cashDrawersPage: {
    activateSalesBox: "Activate Sales Box", //Ativar Caixa de Venda
    checkCashMov: "Check Cash Movements", //Consulta de Movimentos de Caixa
    closeCashRegister: "Close cash register", //Fecho de caixa
    consult: "Consult", //Consultar
    getData: "Get Cash Drawer Data",
    includesPattyCash: "Cash value includes patty cash?", //Valor do Numerário inclui Fundo de Maneio?
    initialPattyCash: "Initial Patty Cash", //Fundo de maneio inicial
    msgCloseSuccess: "Successful Cash Closing", //Fecho de Caixa realizado com Sucesso
    msgMovemNotAvailable: "It was not possible to register the Cash Movement.", //Não foi possível registar o Movimento de Caixa 111
    msgOpenClose: "The Cashier must be opened/selected before carrying out any operation", //É necessário proceder à abertura/selecção de Caixa antes de realizar qualquer operação
    msgOpenSuccess: "Successful Cash Opening", //Abertura de Caixa realizada com Sucesso
    openCashRegister: "Open Cash Register", //Abertura de caixa
    pattyCashNextDay: "Patty Cash the next day", //Fundo Maneio Próx. Dia
  },
  cashDrawersSearchPage: {
    gettingResults: "Getting Sale Search Results",
  },
  noResults: "No Results",

  //Validações
  addressMax200: "Address must be a maximum of 200 characters", // Endereço deve ter no máximo 200 caracteres
  countryMax2: "Country must be a maximum of 2 characters", // País deve ter no máximo 2 caracteres
  errorInvalidEmail: "Invalid E-mail", //E-mail Inválido
  errorInvalidMobile: "Invalid Mobile number", //Nr. Telemóvel inválido
  errorInvalidTIN9: "TIN must be 9 digits", //NIF deve ter 9 dígitos
  errorInvalidTIN: "Invalid TIN", //NIF Inválido
  expiredPassword: "Expired password", //Palavra-passe Expirada
  expiredPasswordMessage: "You need to change your password.", //É necessário proceder à alteração da sua palavra-passe.
  firstAndLastName: "First and Last Name", //Primeiro e Último Nome
  locationMax100: "Location must be a maximum of 100 characters", // Localidade deve ter no máximo 100 caracteres
  mandatoryField: "Mandatory field", //Campo obrigatório
  nameMax20: "Name must be a maximum of 20 characters", //Nome deve ter no máximo 20 caracteres
  noStock: "No Stock", //Sem stock
  passwordRequired: "Password required", //Palavra-passe requerida
  paymentMethodDoesNotAllowChange: "Payment method does not allow change", //O Método de pagamento não permite troco
  postalCodeMax20: "Postal Code must be a maximum of 20 characters", //Cód. Postal deve ter no máximo 20 caracteres
  usernameRequired: "Username required", //Nome de Utilizador requerido
  value100: "Value should start at 100", //Valor deve começar por 100
  value10to20: "Value must be between 10 and 20 characters", //Valor deve ter entre 10 e 20 caracteres
  valueAbove0: "Value should be equal or superior to 0", //Valor deve ser igual ou superior a 0
  valuesBetween0to100: "Valid values: between 0 and 100", //Valores válidos: entre 0 e 100
  valuesBetween0toX: "Valid values: between 0 and ", //Valores válidos: entre 0 e
  itemsMenuLevelsCardPage: {
    allowed: "Allowed", //Apenas são permitidos
    itemsInLevel: "items in level", //artigos no nível
    maxChoices: "Maximum number of choices", //Nr. Máximo de Escolhas
    summary: "Summary", //Resumo
  },

  //Páginas específicas:
  //--Página de Administração
  admin: {
    administration: "Administration", //Administração
    //1º cartão
    deviceConfiguration: "Device Configuration", //Configuração Dispositivos
    printer: "Printer", //Impressora
    scale: "Scale", //Balança
    cashDrawer: "Cash Drawer", //Caixa
    virtualKeyboard: "Virtual Keyboard", //Teclado virtual
    //2º cartão
    generalSettings: "General Settings", //Configurações gerais
    productDescription: "Product Description", //Descrição dos Artigos
    employeeSelection: "Employee must be selected", //Obrigatório selecionar Empregado
    sizePrintDoc: "Print size of sales documents", //Tamanho Impressão Documentos Venda
    receiptRoll: "Receipt Roll", //Rolo
    a4: "A4", //A4
    short: "Short", //Curta
    complete: "Complete", //Completa
    useLocalTime: "Use local time when issuing invoices",
    //3º cartão
    dataSynchronization: "Data Synchronization", // Sincronizar informação
    updateMessage: "Select identities to update information.", //Selecione as entidades para atualizar com a informação mais recente disponivel.
    //options
    families: "Families", //Famílias
    product: "Product", //Artigos
    cashier: "Cashier", //Empregados
    cashRegister: "Cash Register", //Caixas
    infoNotPossible: "It was not possible accessing information from ", //Não foi possível obter a informação de
    syncMessageSuccess: "Synchronization of information was successful", //Sincronização de Informação realizada com Sucesso.
  },

  authentication: {
    login_error: "The specified username and password could not be validated. Please try again.",
    forbidden: "No permission",
  },
};
