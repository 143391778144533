import { apiFetch } from "@/_helpers";

export const modelExtensionServices = {
  getAllModelExtensionFields,
};

function getAllModelExtensionFields(synccounter) {
  let fetchPath = "pos/modelextensionfields/";

  if (synccounter) {
    fetchPath += "?synccounter=" + synccounter;
  }
  return apiFetch(fetchPath);
}
