import Vue from "vue";
import VueRouter from "vue-router";
import { store } from "@/store";
import { setAxiosDefaultUrl } from "@/_helpers";
import vuetify from "../plugins/vuetify";
Vue.use(VueRouter);

// Lazy Loading Routes
const Login = () => import(/* webpackChunkName: "core" */ "../views/core/Login");
const NotFound = () => import(/* webpackChunkName: "core" */ "../components/NotFound");
const Landing = () => import(/* webpackChunkName: "core" */ "../views/core/Landing");
const Tenant = () => import(/* webpackChunkName: "core" */ "../views/core/Tenant");
const AppLayout = () => import(/* webpackChunkName: "core" */ "../layouts/AppLayout");
const Home = () => import(/* webpackChunkName: "home" */ "../views/Home");
const Catalog = () => import(/* webpackChunkName: "catalog" */ "../views/catalog/Catalog");
const CatalogGroupDetail = () => import(/* webpackChunkName: "catalog-group" */ "../views/catalog/CatalogGroupDetail");
const CatalogItemDetail = () => import(/* webpackChunkName: "catalog-item" */ "../views/catalog/CatalogItemDetail");
const CatalogSearchResults = () =>
  import(/* webpackChunkName: "catalog-search" */ "../views/catalog/CatalogSearchResults");
const CheckoutCart = () => import(/* webpackChunkName: "cart" */ "../views/CheckoutCart");
//daniel.silva temporário tiger
const ShelfCheckoutHome = () => import(/* webpackChunkName: "cart" */ "../views/selfCheckout/CheckoutHome");
const ShelfCheckoutCartClient = () => import(/* webpackChunkName: "cart" */ "../views/selfCheckout/CheckoutClient");
const ShelfCheckoutCartPayment = () => import(/* webpackChunkName: "cart" */ "../views/selfCheckout/CheckoutPayment");
const SavedCarts = () => import(/* webpackChunkName: "cart" */ "../views/SavedCarts");
const SalesHistory = () => import(/* webpackChunkName: "cart" */ "../views/SalesHistory");
const Administration = () => import(/* webpackChunkName: "cart" */ "../views/Administration");
const CashDrawers = () => import(/* webpackChunkName: "cart" */ "../views/CashDrawers");
const CartReturn = () => import(/* webpackChunkName: "cart" */ "../views/CartReturn");
const Reports = () => import(/* webpackChunkName: "cart" */ "../views/Reports");
const SalesmenSessions = () => import(/* webpackChunkName: "cart" */ "../views/SalesmenSessions");
const ExpiredPassword = () => import(/* webpackChunkName: "cart" */ "../views/ExpiredPassword");

const routes = [
  {
    path: "/:tenant/:store/",
    component: Tenant,
    children: [
      {
        path: "login",
        name: "login",
        component: Login,
        context: "general",
      },
      {
        path: "expired-password",
        name: "expired-password",
        component: ExpiredPassword,
      },
      {
        path: "",
        name: "root",
        component: AppLayout,
        children: [
          {
            path: "home",
            name: "home",
            component: Home,
            meta: {
              toolbarTitle: "$vuetify.start",
              parentMenuPath: "/home",
              context: "general",
            },
          },
          {
            path: "selfcheckout",
            name: "selfcheckout",
            component: ShelfCheckoutHome,
            meta: {
              toolbarTitle: "",
              parentMenuPath: "/selfcheckout",
            },
          },
          {
            path: "catalog",
            name: "catalog-home",
            component: Home,
            meta: {
              toolbarTitle: "$vuetify.start",
              parentMenuPath: "/home",
              context: "general",
            },
          },
          {
            path: "catalog/:zone",
            name: "catalog",
            component: Catalog,
            meta: {
              toolbarTitle: "$vuetify.catalog",
              parentMenuPath: "/catalog",
              context: "sale",
              blockSaleArea: false,
            },
          },
          {
            path: "catalog/search",
            name: "catalog-search-results",
            component: CatalogSearchResults,
            meta: {
              toolbarTitle: "$vuetify.catalog",
              parentMenuPath: "/catalog",
              context: "sale",
              blockSaleArea: false,
            },
          },
          {
            path: "catalog/:zone/:id",
            name: "catalog-detail",
            component: Catalog,
            meta: {
              toolbarTitle: "$vuetify.catalog",
              parentMenuPath: "/catalog",
              context: "sale",
              blockSaleArea: false,
            },
          },
          {
            path: "catalog/detail/:detail",
            name: "catalog-detail-items",
            component: CatalogGroupDetail,
            meta: {
              toolbarTitle: "$vuetify.catalog",
              parentMenuPath: "/catalog",
              context: "sale",
              blockSaleArea: false,
            },
          },
          {
            path: "catalog/item/:id",
            name: "catalog-item-detail",
            component: CatalogItemDetail,
            meta: {
              toolbarTitle: "$vuetify.catalog",
              parentMenuPath: "/catalog",
              context: "sale",
              blockSaleArea: false,
            },
          },
          {
            path: "cart/checkout/details",
            name: "shop-cart-checkout-details",
            component: CheckoutCart,
            meta: {
              toolbarTitle: "Checkout",
              parentMenuPath: "/catalog",
              context: "sale",
              blockSaleArea: true,
            },
          },
          {
            path: "cart/selfcheckout/details/client",
            name: "shop-cart-selfcheckout-details-client",
            component: ShelfCheckoutCartClient,
            meta: {
              toolbarTitle: "Checkout",
              parentMenuPath: "/catalog",
              context: "sale",
              blockSaleArea: true,
            },
          },
          {
            path: "cart/checkout/details/payment",
            name: "shop-cart-selfcheckout-details-payment",
            component: ShelfCheckoutCartPayment,
            meta: {
              toolbarTitle: "Checkout",
              parentMenuPath: "/catalog",
              context: "sale",
              blockSaleArea: true,
            },
          },
          {
            path: "cart/checkout/return",
            name: "shop-cart-checkout-return",
            component: CartReturn,
            meta: {
              toolbarTitle: "Checkout",
              parentMenuPath: "/catalog",
              context: "sale-return",
              blockSaleArea: false,
            },
          },
          {
            path: "cart/checkout/return/details",
            name: "shop-cart-checkout-return-details",
            component: CheckoutCart,
            meta: {
              toolbarTitle: "Checkout",
              parentMenuPath: "/catalog",
              context: "sale-return",
              blockSaleArea: true,
            },
          },
          {
            path: "savedCarts",
            name: "sale-saved-carts",
            component: SavedCarts,
            meta: {
              toolbarTitle: "$vuetify.suspendedSales",
              parentMenuPath: "/savedCarts",
            },
          },
          {
            path: "salesHistory",
            name: "sales-history",
            component: SalesHistory,
            meta: {
              toolbarTitle: "$vuetify.sales",
              parentMenuPath: "/salesHistory",
              context: "salesHistory",
            },
          },
          {
            path: "salesmenSessions",
            name: "salesmen-sessions",
            component: SalesmenSessions,
            meta: {
              toolbarTitle: "$vuetify.sessions",
              parentMenuPath: "/salesmenSessions",
              context: "salesmenSessions",
            },
          },
          {
            path: "cashDrawersManagement",
            name: "cash-drawers-management",
            component: CashDrawers,
            meta: {
              toolbarTitle: "$vuetify.cashDrawers",
              parentMenuPath: "/cashDrawersManagement",
              context: "cashDrawers",
            },
          },
          {
            path: "cashDrawersManagement",
            name: "cash-drawers-management-redirect",
            component: CashDrawers,
            meta: {
              toolbarTitle: "$vuetify.cashDrawers",
              parentMenuPath: "/cashDrawersManagement",
              context: "cashDrawers",
              showModal: true,
            },
          },
          {
            path: "reports",
            name: "reports",
            component: Reports,
            meta: {
              toolbarTitle: "$vuetify.reports",
              parentMenuPath: "/reports",
              context: "reports",
            },
          },
          {
            path: "admin",
            name: "administration",
            component: Administration,
            meta: {
              toolbarTitle: "$vuetify.admin.administration",
              parentMenuPath: "/administration",
              context: "administration-configuration",
            },
          },
        ],
      },
    ],
  },
  {
    // not found handler
    path: "/",
    component: Landing,
    name: "landing",
  },
  {
    // not found handler
    path: "*",
    component: NotFound,
    name: "not-found",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

router.beforeEach(async (to, from, next) => {
  /**
   * https://router.vuejs.org/guide/advanced/navigation-guards.html
   * A Navigation Guard
   * Redirect to login page if not logged in and trying to access a restricted page
   * Redirect to profile page if user is logged in but has not accepted the terms and conditions
   * Redirect to the Home page if the Shop's module is not available and the user is trying to access a page related
   *  to the Shop's module
   *
   * @param to: the target Route Object being navigated to
   * @param from: the current route being navigated away from
   * @param next: a function
   */
  Vue.$log.debug(
    "Router Navigation Guard | To FullPath :" +
      to.fullPath +
      "| To Name :" +
      to.name +
      " | To Context : " +
      to.meta.context +
      "| Tenant: " +
      to.params.tenant +
      " | Store: " +
      to.params.store
  );
  Vue.$log.debug(
    "Router Navigation Guard | From FullPath :" +
      from.fullPath +
      "| From Name :" +
      from.name +
      " | From Context : " +
      from.meta.context +
      "| Tenant: " +
      from.params.tenant +
      " | Store: " +
      from.params.store
  );

  const publicPages = ["login", "not-found", "landing", "expired-password"];
  const authRequired = !publicPages.includes(to.name);
  const user = localStorage.getItem("user");
  //TODO melhorar estas regras
  const userTenant = user ? JSON.parse(user).tenant : undefined;
  const userStore = user ? JSON.parse(user).store : undefined;

  if (to.params.tenant && to.params.store) {
    // console.log("Global set tenant & store");
    let first_time = !Vue.prototype.tenant_id;
    Vue.prototype.tenant_id = to.params.tenant;
    Vue.prototype.store_id = to.params.store;
    if (first_time) {
      setAxiosDefaultUrl();
    }
  }

  if (to.name === "expired-password") {
    return next();
  }

  if ((!to.params.tenant || !to.params.store) && to.name !== "landing") {
    return next({ name: "landing" });
  } else if (authRequired && (!user || userTenant !== to.params.tenant || userStore !== to.params.store)) {
    return next({
      name: "login",
      params: { tenant: to.params.tenant, store: to.params.store },
      replace: true,
    });
  } else {
    //TODO: ana.castro -> tentar usar uma Dialog em vez de um alert.
    //If the user leaves the Sale's section and there are Items on the Cart, the user must be asked if the Items
    //  should be stored on the Saved Carts
    if (from.meta.context === "sale" && to.meta.context !== "sale" && to.meta.context !== "sale-return") {
      var cartSize = store.getters["cart/cartSize"];
      if (cartSize > 0) {
        let answer = false;
        if (to.name !== "cash-drawers-management-redirect") {
          // Devia funcionar: Vue.$vuetify
          answer = window.confirm(vuetify.framework.lang.t("$vuetify.msgSuspendSaleAlert"));
        } else {
          answer = true;
        }
        if (answer) {
          let customer = null;
          let extraIdentifier = null;
          let selectedSalesman = store.getters["salesmen/getSelectedSalesman"]["data"]["SalesmanShortName"];
          store.dispatch("cart/saveActiveCart", {
            customer,
            extraIdentifier,
            selectedSalesman,
          });
          store.dispatch("cart/setCartEmpty");
          next();
        } else {
          return null;
        }
      }
    }

    if (from.meta.context === "sale-return" && to.meta.context !== "sale-return") {
      store.dispatch("cart/setCartEmpty");
    }

    //To perform a Sale or a Return, it is mandatory to choose the Salesman who is responsible for the action
    //To access the Reports page, it is mandatory to choose the Salesman who is responsible for the action
    //To access the CashDrawers page, it is mandatory to choose the Salesman who is responsible for the following
    //  actions: open/close the cashdrawers; access the Cashdrawers reports
    let isNecessaryToIdentifySalesman = store.getters["salesmen/isNecessaryToIdentifySalesman"]({ from, to });
    if (isNecessaryToIdentifySalesman) {
      if (!from.name) {
        return next({
          name: "home",
          params: { tenant: to.params.tenant, store: to.params.store },
          replace: true,
        });
      } else {
        store.dispatch("salesmen/validateRouterIdentifySalesman", { to: to });
        //When the user navigates from the Main Page to the Catalog, the route includes a parameter indicating which
        // Sale Price Range was chosen.
        //However, if the user has to choose the Sale's Salesman, a dialog is displayed and the parameters sent on the
        // route to the Catalog's page are lost.
        //For that reason, here the value of the chosen Sale Price Range is stored on the Cart's store.
        //@modified ana.castro 2022.12.05 SAFO-47
        // if (to.params.salePriceRange) {
        if (to.params.zone) {
          // store.dispatch("cart/setCartSalePriceRange", to.params.salePriceRange);
          store.dispatch("cart/setCartZone", to.params.zone);
        }
        return null;
      }
    } else {
      let hasSelectSalesmanAccessToRouter = store.getters["salesmen/hasSelectSalesmanAccessToRouter"](to);
      if (!hasSelectSalesmanAccessToRouter["value"]) {
        store.dispatch(
          "alert/warning",
          vuetify.framework.lang.t("$vuetify.warningNoPermissionsTo") + hasSelectSalesmanAccessToRouter["verbose"] + "'"
        );
        return null;
      }
    }

    //If the user leaves the Checkout interface,
    //  the flag which indicates that a Cart is being Finalized, must be set to False
    var cartCheckoutInProgress = store.getters["cart/cartCheckoutInProgress"];
    if (
      cartCheckoutInProgress &&
      to.name !== "shop-cart-checkout-details" &&
      to.name !== "shop-cart-checkout-return-details"
    ) {
      store.dispatch("cart/setCheckoutCartStatus", "closed");
    }

    if (to.name === "tenant" || to.name === "root") {
      return next({
        name: "home",
        params: { tenant: to.params.tenant, store: to.params.store },
        replace: true,
      });
    } else {
      store.dispatch("configs/setSaleAreaBlocked", to.meta.blockSaleArea === true);

      next();
    }
  }
});
