import { apiFetch, getApiBaseStore, getApiParamsString } from "@/_helpers";

export const familiesService = {
  getAll,
};

async function getAll(synccounter) {
  let families = [];

  let result = [];
  let i = 1;
  let fetchPath = "pos/families/?store=" + getApiBaseStore();

  if (synccounter) {
    var params = {
      synccounter: synccounter,
    };

    fetchPath += "&" + getApiParamsString(params);
  }

  do {
    result = await apiFetch(fetchPath + "&page=" + i);
    families = families.concat(result.results);
    i += 1;
  } while (result.next && i < 2000);

  return families;
}
