import { ca } from "vuetify/lib/locale";

export default {
  ...ca,

  languageName: "CA - Català",
  languageFlag: "es-ct",

  //Erros
  errorCreditNotesIssue: "No more credit notes can be issued for the Invoice", //Não é possível emitir mais notas de crédito para a Fatura
  errorGettingCountries: "Error getting Countries", //"Erro a obter Países"
  errorGettingCustomerDetail: "Getting the Customer Detail",
  errorIdAvailableCampaigns: "Could not identify available campaigns", //Não foi possível identificar Campanhas Disponíveis
  errorIdAvailableVouchers: "No Available Vouchers could be identified.", //Não foi possível identificar Vales Disponíveis.
  errorInfoEmployee: "Error obtaining information from Employee ", //Erro a obter informação do Empregado
  errorInvalidLoyaltyCard: "Invalid Loyalty Card", //Cartão de Fidelização Inválido
  errorNoSaleItems: "There are no items in the Sale", //Não há artigos na Venda
  errorRegisterBudget: "It was not possible to register the budget.", //Não foi possível registar o Orçamento.
  errorRegisterClient: "It was not possible to register client.", //Não foi possível registar o Cliente.
  errorRegisterSale: "It was not possible to register sale.", //Não foi possível registar a venda.
  errorRegisterSessionMovement: "It was not possible to register the Session Movement.", //Não foi possível registar o Movimento de Sessão.
  errorSearchValueNotIndicated: "No value was indicated for search.", //Não foi indicado um valor para pesquisa.
  errorStoreZone: "S'ha produït un error en obtenir zones de botigues", // Erro a obter Zonas da Loja
  errorValidatingCredentials: "It was not possible to validate the Employee and password indicated. ", //Não foi possível validar o Empregado e palavra-passe indicados.
  itemNotFoundMessage: "Error en afegir un article per a la venda. No s'ha trobat l'article.", //Erro ao Adicionar Artigo à Venda. Artigo não encontrado.
  catalogSearchResults: {
    errorIdArticles: "It was not possible to identify articles according to the survey.", //Não foi possível identificar Artigos de acordo com a pesquisa.
    errorNoSearch: "No search value was indicated.", //Não foi indicado um valor para pesquisa.
  },
  errorStockExit: "S'ha produït un error en obtenir la sortida justificada.",
  notFoundStockExit: "No s'ha trobat la sortida justificada.",
  invalidItemStockExit: "S'han trobat articles no sincronitzats a la sortida justificada.",
  errorCancelDocument: "No és possible cancel·lar el document",
  errorItemToReturn: "No es poden afegir articles a una devolució.",
  errorItemInfoExtra: "La venda sense articles no permet afegir articles informatius/extra.",

  //Legendas, Botões e Opcões
  add: "Afegeix", //Adicionar
  addressClient: "Address", //Endereço
  backoffice: "Backoffice", //Backoffice
  billingData: "Billing data", //Dados faturação
  campaigns: "Campanyes",
  cancel: "Cancel · lar", //Cancelar
  cancelAndPrint: "Suspensió i Impressió", //Suspender e Imprimir
  cancelDocument: "Anul·lació", //Anulação
  fromStockExit: "Venda de Sortida Justificada",
  cashDrawers: "Calaixos d'Efectiu", //Caixas
  catalog: "Catàleg", //Catálogo
  change: "Canvis",
  clean: "Netejar", //Limpar
  client: "Client",
  close: "Tancar", //Fechar
  code: "Code", //Código
  consultSales: "Consulta Vendes", //Consulta de Vendas
  consultSalesDescription: "Consulta els documents de venda emesos", //Consulte documentos de venda emitidos
  consultSuspended: "Consulta documents suspesos", //Consulte documentos em suspenso
  country: "Country", //País
  customerLoyaltyCard: "Targeta de Fidelització", //Cartão Fidelização
  customerMobileTelephone: "Número de Mòbil", //Nr. Telemóvel
  customerSearchMessage: "Cerca per Targeta de Fidelització, CIF o Número de Mòbil", //Pesquise pelo Cartão de Fidelização, NIF ou Número de Telemóvel
  date: "Data",
  discount: "Descompte", //Desconto
  discountShort: "Desc.%", //Desc.%
  discountType: "Tipus Descompte",
  download: "Descarregar",
  email: "Correu electrònic", //E-mail
  end: "Acabar", //Finalizar
  finalClient: "Final Client", //Cliente final
  globalDiscount: "Descompte Global", //Desconto Global
  goBackToLogin: "Torna a iniciar sessió", //Voltar para o login
  identifier: "Identifier", //Identificador
  location: "Location", //Localidade
  logout: "Tancar Sessió", //LogOut
  loyalty: "Campanyes de Fidelització", //Campanhas Fidelização
  loyaltyCardVoucherNumberMessage: "Targeta de fidelitat/Núm. Val", //Cartão Fidelização/Nr. Vale
  moneyRemaining: "Falta", //Faltam
  more: "Més", //Mais
  name: "Nom", //Nome
  newClient: "Nou Client", //Novo Cliente
  numberOfCopies: "Quantitat d'impressió", //Quantidade Impressão
  obs: "Comentaris", //Observações
  offerDocument: "Bitllet Oferta", //Talão Oferta
  ok: "Ok", //Ok
  open: "Obert", //Abrir
  openCashDrawer: "Obriu Calaix", //Abrir Caixa
  password: "Contrasenya", //Palavra-passe
  pattyCash: "Fons de Gestió", //Fundo de Maneio
  pay: "Pagar", //Pagar
  payment: "Payment", //Pagamento
  paymentMethod: "Mètode de Pagament", //Método de Pagamento
  paymentMethods: "Mètodes de Pagament", //Métodos de Pagamento"
  percentage: "Percentatge", //Percentagem
  points: "Punts", //Pontos
  postalCode: "Postal Code", //Código Postal
  print: "Imprimir", //Imprimir
  quantity: "Duantitat", //Quantidade
  reports: "Informes", //Relatórios
  resumeSale: "Resum", //Retomar
  return: "Tornar", //Devolver
  returnDocument: "Devolució", //Devolução
  sale: "Venda", //Venda
  saleDocumentDate: "Data Document", //Data Documento
  sales: "Vendes", //Vendas
  salesDescription: "Crear nous documents de vendes", //Crie novos documentos de venda
  salesmenName: "Servit per", //Atendido por
  salesmenSessionClose: "Tancar Sessió", //Fechar Sessão
  salesmenSessionOpen: "Obert Sessió", //Abrir Sessão
  salesSub: "View sales totals, VAT totals and payment methods", //Visão dos totais de vendas, totais de IVA e formas de pagamento
  save: "Guardar", //Guardar
  savedSales: "Sense Vendes Suspeses", //Sem Vendas Suspensas
  search: "Buscar", //Pesquisar
  searchDocument: "Buscar Núm. Document", //Pesquisar Nr. Documento
  selectDate: "Seleccioneu Data", //Selecionar Data
  selectSalesman: "Seleccioneu Empleat", //Selecionar Empregado
  sessions: "Sessions", //Sessões
  sessionsProcedure: "Procedir a obrir i tancar sessions", //Proceda à Abertura e Fecho de Sessões
  start: "Començar", //Início
  submit: "Submetre", //Submeter
  suspendedSales: "Vendes Suspeses", //Vendas Suspensas
  taxNumber: "CIF", //NIF
  total: "Total", //Total
  unitPrice: "Preu Unitari", //Preço Unitário
  updatePassword: "Actualitza Contrasenya", //Atualizar Password
  validate: "Validate", //Validar
  valuePay: "Valor", //Valor
  voucherCode: "Codi de Val", //Código do Vale

  //Mensagens de Feedback e Confirmação
  appliedVatExempt: "Actiu exempt d'IVA", //IVA Isento Aplicado
  applyVatExempt: "Aplicar Exempt d'IVA", //Aplicar Isenção IVA
  cashDrawersProcedure: "Procediu a obrir i tancar els calaixos d'efectiu", //Proceda à Abertura e Fecho de Caixas
  confirmConfirmCancelDocumentMessage: "Aquesta és una operació irreversible.", //Esta é uma operação irreversível.
  confirmConfirmCancelDocumentTitle: "Esteu segur que voleu cancel·lar la venda?", //Tem a certeza que pretende Anular a Venda?
  confirmLogout: "Finalitzar la Sessió?", //Encerrar a Sessão?
  msgAreYouSure: "Are you sure?", //Tem a certeza?
  msgAreYouSureRemoveItems: "Are you sure you want to remove all the items from the sale?", //Tem a certeza que pretende remover todos os Artigos da Venda?
  msgAreYouSureSuspendSale: "Are you sure you want to Suspend the Sale?", //Tem a certeza que pretende Suspender a Venda?
  msgCheckInstructions: "Check the instructions on the payment terminal", //Verifique as instruções no terminal de pagamento
  msgCloseSessionSuccess: "Successful closing of session.", //Fecho de Sessão realizada com Sucesso.
  msgContentNotAvailable: "Content not available at the moment.", //Conteúdo não disponível de momento.
  msgDetailsInvoice: "Enter your details if you want an invoice with tax details or click on Payment to proceed", //Introduza os seus dados se pretender fatura com dados fiscais ou clique em Pagamento para avançar
  msgExpiredVoucher: "The Refund Voucher expired on ", //O Talão de Reembolso expirou em
  msgInvalidSlip: "Invalid reimbursement slip identifier.", //Identificador do Talão de Reembolso inválido.
  msgOpenEmployeeSessions: "It is necessary to open Employee Sessions", //É necessário proceder à abertura de Sessões de Empregados
  msgOpenSessionSuccess: "Successful opening of session.", //Abertura de Sessão realizada com Sucesso.
  msgSuspendSaleAlert: "Do you want to suspend this sale? If you don't, you'll lose the items in the sale!", //Quer suspender esta venda? Se não suspender, perderá os artigos da venda!
  msgUsedVoucher: "The Refund Slip has already been used.", //O Talão de Reembolso já foi utilizado.
  msgCancelDocument: "Cancel·lació del document realitzada amb èxit.",
  pageNotFound: "Page not found", //Página não encontrada
  removeVatExempt: "Elimina l'exempt d'IVA", //Remover Inseção IVA
  startPurchase: "Start your purchase", //Inicie a sua compra
  tryAgain: "Please try again.", //Por favor tente novamente
  warningNoPermissionsTo: "No permissions to access to '", //Sem Permissões para aceder a '
  welcome: "Welcome", //Bem-Vindo
  cashDrawersPage: {
    activateSalesBox: "Activa la caixa de vendes", //Ativar Caixa de Venda
    checkCashMov: "Consulta de moviment d'efectiu", //Consulta de Movimentos de Caixa
    closeCashRegister: "Tancament de Caixa", //Fecho de caixa
    consult: "Consulta", //Consultar
    getData: "Get Cash Drawer Data",
    includesPattyCash: "El valor en efectiu inclou Fons de Gestió?", //Valor do Numerário inclui Fundo de Maneio?
    initialPattyCash: "Fons de Gestió Inicial", //Fundo de maneio inicial
    msgCloseSuccess: "Tancament d'efectiu realitzat amb èxit", //Fecho de Caixa realizado com Sucesso
    msgMovemNotAvailable: "Contingut no disponible actualment. No es pot registrar moviment d'efectiu", //Não foi possível registar o Movimento de Caixa.
    msgOpenClose: "Cal obrir/seleccionar el Caixer abans de realitzar qualsevol operació", //É necessário proceder à abertura/selecção de Caixa antes de realizar qualquer operação
    msgOpenSuccess: "Obertura d'efectiu realitzada amb èxit", //Abertura de Caixa realizada com Sucesso
    openCashRegister: "Obertura de Caixa", //Abertura de caixa
    pattyCashNextDay: "Fons de Gestió Següent. Dia", //Fundo Maneio Próx. Dia
  },
  cashDrawersSearchPage: {
    gettingResults: "Getting Sale Search Results", //A obter resultados de pesquisa
  },
  noResults: "Sense resultats",

  //Validações
  addressMax200: "Address must be a maximum of 200 characters", // Endereço deve ter no máximo 200 caracteres
  countryMax2: "Country must be a maximum of 2 characters", // País deve ter no máximo 2 caracteres
  errorInvalidEmail: "Invalid E-mail", //E-mail Inválido
  errorInvalidMobile: "Invalid Mobile number", //Nr. Telemóvel inválido
  errorInvalidTIN9: "TIN must be 9 digits", //NIF deve ter 9 dígitos
  errorInvalidTIN: "Invalid TIN", //NIF Inválido
  expiredPassword: "Contrasenya Caducada", //Password Expirada
  expiredPasswordMessage: "Heu de canviar la vostra contrasenya.", //É necessário proceder à alteração da sua palavra-passe.
  firstAndLastName: "First and Last Name", //Primeiro e Último Nome
  locationMax100: "Location must be a maximum of 100 characters", // Localidade deve ter no máximo 100 caracteres
  mandatoryField: "Mandatory field", //Campo obrigatório
  nameMax20: "Name must be a maximum of 20 characters", //Nome deve ter no máximo 20 caracteres
  noStock: "No Stock", //Sem stock
  passwordRequired: "Password required",
  paymentMethodDoesNotAllowChange: "El Mètode de Pagament no permet canvis", //O Método de pagamento não permite troco
  postalCodeMax20: "Postal Code must be a maximum of 20 characters", //Cód. Postal deve ter no máximo 20 caracteres
  usernameRequired: "Username required", //Nome de Utilizador requerido
  value100: "Value should start at 100", //Valor deve começar por 100
  value10to20: "Value must be between 10 and 20 characters", //Valor deve ter entre 10 e 20 caracteres
  valueAbove0: "Value should be equal or superior to 0", //Valor deve ser igual ou superior a 0
  valuesBetween0to100: "Valid values: between 0 and 100", //Valores válidos: entre 0 e 100
  valuesBetween0toX: "Valid values: between 0 and ", //Valores válidos: entre 0 e
  itemsMenuLevelsCardPage: {
    allowed: "Allowed", //Apenas são permitidos
    itemsInLevel: "items in level", //artigos no nível
    maxChoices: "Maximum number of choices", //Nr. Máximo de Escolhas
    summary: "Summary", //Resumo
  },

  //Páginas específicas:
  //--Página de Administração
  admin: {
    administration: "Administració", //Administração
    //1º cartão
    deviceConfiguration: "Configuració Dispositiu", //Configuração Dispositivos
    printer: "Impressora", //Impressora
    scale: "Balanç", //Balança
    cashDrawer: "Calaix d'Efectiu", //Caixa
    virtualKeyboard: "Teclat Virtual", //Teclado virtual
    //2º cartão
    generalSettings: "Configuració General", //Configurações gerais
    productDescription: "Descripció dels Articles", //Descrição dos Artigos
    employeeSelection: "Obligatori seleccionar Empleat", //Obrigatório selecionar Empregado
    sizePrintDoc: "Mida d'Impressió de Documents de Venda", //Tamanho Impressão Documentos Venda
    receiptRoll: "Rotlla", //Rolo
    a4: "A4", //A4
    short: "Curt", //Curta
    complete: "Completa", //Completa
    useLocalTime: "Utilitzeu l'hora local a l'hora d'emetre factures",
    //3º cartão
    dataSynchronization: "Sincronitzar la Informació", //Sincronizar informação
    updateMessage: "Select identities to update information.", //Selecione as entidades para atualizar com a informação mais recente disponivel.
    //options
    families: "Famílies", //Famílias
    product: "Articles", //Artigos
    cashier: "Empregados", //Empregados
    cashRegister: "Caixa Registradora", //Caixas
    infoNotPossible: "No fos possible obtenir una informació de ", //Não foi possível obter a informação de
    syncMessageSuccess: "Sincronització d'informació correcta.", //Sincronização de Informação realizada com Sucesso.
  },

  authentication: {
    login_error: "El nom d'usuari i la contrasenya especificats no s'han pogut validar. Siusplau torna-ho a provar.",
    forbidden: "Sense permís",
  },
};
