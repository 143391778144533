import { apiPost } from "@/_helpers";

export const dataLogServices = {
  setLog,
};

// function setLog(attributes) {
function setLog(data) {
  return apiPost("pos/posdatalog/", data);
}
