import { docTypesServices } from "@/services";
import { getLocalDataBase } from "@/_helpers";
import Dexie from "dexie";

export const docTypes = {
  namespaced: true,
  state: {
    activeForSale: localStorage.getItem("activeDocTypeSale") || "FR",
    activeForReturn: localStorage.getItem("activeDocTypeReturn") || "NC",
  },
  actions: {
    // eslint-disable-next-line
    setConfiguration({ commit, dispatch }, { forceUpdate, autoSync }) {
      let startDate = new Date(Date.now()).toLocaleString("pt");
      //Initialize the value of the synccounter which will filter the information will get
      let synccounter = 1;
      //Open database and make it start functioning.
      let db = getLocalDataBase();
      db.open();

      //Use Dexie.spawn() or Dexie.async() to enable a synchronous-like programming style.
      var spawn = Dexie.spawn;
      // Interact With Database
      spawn(function* () {
        // Let's query the db
        //Getting the last information stored on the Entities table, to the Payment Method's Entity
        if (forceUpdate === 0) {
          var entityInfo = yield db.entities.where("entity").equals("docTypes").toArray();
          if (entityInfo.length > 0) {
            synccounter = entityInfo.map((f) => f.synccounter)[0];
          }
        }
        //The "put" adds a new object or updates an existing object.
        //  I've chosen to use it instead of the "update", because the update only updates an existing object.
        //  Therefore, using the "update" it would have been necessary to verify if the "update" returned 1 and if not
        //  performing an insert.
        db.entities.put({
          entity: "docTypes",
          startDate: new Date().toISOString(),
          endDate: null,
          nrSyncEntities: null,
          synccounter: synccounter,
        });

        //Accessing the backend API to get all the DocTypes which match with the identified synccounter
        let result = yield docTypesServices.getAll(synccounter);

        //Iterating over the retrieved DocTypes:
        //1 - building the dictionary which will add the DocTypes to the database
        //2 - obtaining the maximum synccounter of the retrieved DocTypes
        let maxSynccounter = 0;
        let bulkDocTypes = [];
        for (var i = 0; i < result.length; i++) {
          bulkDocTypes.push({
            id: result[i]["Id"],
            description: result[i]["Description"],
            code: result[i]["Code"],
            nature: result[i]["Nature"],
            synccounter: result[i]["synccounter"],
            data: result[i],
          });

          if (result[i].synccounter > maxSynccounter) {
            maxSynccounter = result[i].synccounter;
          }
        }

        //1 - Adding all given objects to the store.
        //2 - updating the Entities table, setting the new synccounter for the Entity Countries and setting the new
        // last sync date
        if (bulkDocTypes.length > 0) {
          db.docTypes
            //  If an object with the same primary key already exists, it will be replaced with the given object.
            //  If it does not exist, it will be added.
            .bulkPut(bulkDocTypes)
            .then(function () {
              db.entities.put({
                entity: "docTypes",
                startDate: startDate,
                endDate: new Date(Date.now()).toLocaleString("pt"),
                nrSyncEntities: bulkDocTypes.length,
                synccounter: maxSynccounter,
              });
            })
            // eslint-disable-next-line
            .catch(Dexie.BulkError, function (e) {
              // Explicitly catching the bulkAdd() operation makes those successful
              // additions commit despite that there were errors.
            });
        } else {
          db.entities.put({
            entity: "docTypes",
            startDate: startDate,
            endDate: new Date(Date.now()).toLocaleString("pt"),
            nrSyncEntities: 0,
            synccounter: synccounter,
          });
        }

        //Setting the synchronization to run 3 minutes after the synchronization is finished
        if (autoSync === 1) {
          setTimeout(function () {
            let autoSync = 1;
            let forceUpdate = 0;
            dispatch("setConfiguration", { forceUpdate, autoSync });
          }, 180000);
        }

        // Check if doc types saved in local storage are valid
        let descriptions = yield db.docTypes.toArray().then((l) => l.map((d) => d.code));
        if (!descriptions.includes(localStorage.getItem("activeDocTypeSale"))) {
          commit("setActiveSale", "FR");
        }
        if (!descriptions.includes(localStorage.getItem("activeDocTypeReturn"))) {
          commit("setActiveReturn", "NC");
        }

        // eslint-disable-next-line
      }).catch(function (err) {
        // Catch any error event or exception
        console.error(err.stack || err);
      });
    },
    setActiveSale({ commit }, payload) {
      commit("setActiveSale", payload);
    },
    setActiveReturn({ commit }, payload) {
      commit("setActiveReturn", payload);
    },
  },
  mutations: {
    setActiveSale: (state, value) => {
      state.activeForSale = value;
      localStorage.setItem("activeDocTypeSale", value);
    },
    setActiveReturn: (state, value) => {
      state.activeForReturn = value;
      localStorage.setItem("activeDocTypeReturn", value);
    },
  },
  getters: {
    get: () => (id) => {
      let db = getLocalDataBase();
      return db.docTypes.where("id").equals(id).toArray();
    },

    getActiveSale: (state) => () => {
      return state.activeForSale;
    },

    getActiveReturn: (state) => () => {
      return state.activeForReturn;
    },

    getAll: () => {
      let db = getLocalDataBase();
      return db.docTypes.toArray();
    },
  },
};
