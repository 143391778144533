import { store } from "../store";

export const loader = {
  namespaced: true,
  state: {
    loading: false,
  },
  actions: {
    setLoading({ commit }, payload) {
      commit("setLoading", payload);
      //TODO: comentar
      const error = new Error();
      const stack = error.stack
        .split("\n")
        .slice(3)
        .map((line) => line.replace(/\s+at\s+/, ""))
        .join("\n");

      let actionType = "loadingLayer";
      let actionValue = payload;
      let actionData = { stack: stack };
      let actionIdentifier = undefined;
      store.dispatch("dataLog/setData", {
        actionType,
        actionValue,
        actionData,
        actionIdentifier,
      });
    },
  },
  mutations: {
    setLoading(state, payload) {
      state.loading = payload;
    },
  },
};
