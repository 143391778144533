<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    dark_theme: true,
  }),
  computed: {
    loading() {
      return this.$store.state.loader.loading;
    },
  },
  watch: {
    loading(newState) {
      if (newState) {
        this.loader = this.$loading.show({
          // Optional parameters
          container: null,
          //color: this.$vuetify.theme.themes.light.primary,
          color: "#2196f3", //temporario
          loader: "spinner",
          height: 50,
          //backgroundColor: "#333",
          backgroundColor: "#CCC",
          opacity: 0.8,
          canCancel: false,
        });
      } else {
        if (this.loader !== undefined) this.loader.hide();
      }
    },
  },
  mounted() {
    // let languageCookie = Vue.$cookies.get("django_language");
    let languageCookie = this.$store.getters["configs/chosenLocale"];
    if (languageCookie) {
      this.$vuetify.lang.current = languageCookie;
    }
  },
};
</script>
