import { apiFetch } from "@/_helpers";

export const countriesServices = {
  getAll,
};

async function getAll(synccounter) {
  let countries = [];

  let result = [];
  let i = 1;
  let fetchPath = "pos/countries/";

  if (synccounter) {
    fetchPath += "?synccounter=" + synccounter;
  }

  do {
    result = await apiFetch(fetchPath + "&page=" + i);
    countries = countries.concat(result.results);
    i += 1;
  } while (result.next && i < 2000);

  return countries;
}
