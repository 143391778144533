// import "@fortawesome/fontawesome-free/css/all.css";
import "./fontawesome/fontawesome-pro/css/all.min.css";
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import pt from "../locale/pt.ts";
import en from "../locale/en.ts";
import es from "../locale/es.ts";
import ca from "../locale/ca.ts";
import colors from "vuetify/es5/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    //options: {
    //  customProperties: true,
    //},
    dark: false,
    themes: {
      light: {
        primary: process.env.VUE_APP_VUETIFY_PRIMARY,
        secondary: process.env.VUE_APP_VUETIFY_SECONDARY, //colors.orange.lighten2,
        accent: process.env.VUE_APP_VUETIFY_ACCENT,
        error: process.env.VUE_APP_VUETIFY_ERROR, //colors.red.darken2,
        warning: process.env.VUE_APP_VUETIFY_WARNING, //colors.yellow.darken2,
        info: process.env.VUE_APP_VUETIFY_INFO, //colors.blue.darken2,
        success: process.env.VUE_APP_VUETIFY_SUCCESS, //colors.green.darken2s
      },
      dark: {
        primary: colors.blue.darken2,
        accent: colors.grey.darken3,
        secondary: colors.amber.darken3,
        info: colors.teal.lighten1,
        warning: colors.amber.base,
        error: colors.deepOrange.accent4,
        success: colors.green.accent3,
      },
    },
  },
  lang: {
    locales: { pt, en, es, ca },
    current: "pt",
  },
  icons: {
    iconfont: "fa",
  },
  font: {
    family: "Cairo",
  },
});
