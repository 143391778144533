export const alert = {
  namespaced: true,
  state: {
    type: null,
    message: null,
    trigger: 0,
    dismissible: true,
    icon: "fa fa-times",
    color: null,
  },
  actions: {
    success({ commit }, message) {
      commit("success", message);
    },
    error({ commit }, message) {
      commit("error", message);
    },
    info({ commit }, message) {
      commit("info", message);
    },
    warning({ commit }, message) {
      commit("warning", message);
    },
    clear({ commit }, message) {
      commit("success", message);
    },
  },
  mutations: {
    success(state, message) {
      state.type = "alert-success";
      state.message = message;
      state.trigger += 1;
      state.color = "success";
    },
    error(state, message) {
      state.type = "alert-danger";
      state.message = message;
      state.trigger += 1;
      state.color = "error";
    },
    info(state, message) {
      state.type = "alert-info";
      state.message = message;
      state.trigger += 1;
      state.color = "info";
    },
    warning(state, message) {
      state.type = "alert-warning";
      state.message = message;
      state.trigger += 1;
      state.color = "warning";
    },
    clear(state) {
      state.type = null;
      state.message = null;
      state.trigger = 0;
    },
  },
};
