import { apiFetch, apiPost, getApiBaseStore, getApiParamsString } from "@/_helpers";
import router from "@/router";

export const salesmenService = {
  getAll,
  getAllSalesmenLevels,
  setSalesmenSessions,
};

async function getAll(synccounter) {
  let salesmen = [];

  let result = [];
  let i = 1;
  let fetchPath = "pos/salesmans/?store=" + getApiBaseStore();

  if (synccounter) {
    var params = {
      synccounter: synccounter,
    };

    fetchPath += "&" + getApiParamsString(params);
  }

  do {
    result = await apiFetch(fetchPath + "&page=" + i);
    salesmen = salesmen.concat(result.results);
    i += 1;
  } while (result.next && i < 2000);

  return salesmen;
}

async function getAllSalesmenLevels(synccounter) {
  let salesmenLevels = [];

  let result = [];
  let i = 1;
  let fetchPath = "pos/salesmanlevels/?synccounter=" + synccounter;

  do {
    result = await apiFetch(fetchPath + "&page=" + i);
    salesmenLevels = salesmenLevels.concat(result.results);
    i += 1;
  } while (result.next && i < 2000);

  return salesmenLevels;
}

function setSalesmenSessions(attributes) {
  let submitData = {};

  submitData = {
    StoreId: router.currentRoute.params.store,
    SalesmanId: attributes.salesman,
    StartTime: attributes.startTime,
    EndTime: attributes.endTime,
  };

  return apiPost("pos/salesmansessions/", submitData);
}
