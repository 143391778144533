import Vue from "vue";
import { getApiBaseUrl } from "@/_helpers";

const initialPrintPageSize = JSON.parse(localStorage.getItem("printPageSize"));
const initialSelectSalesman = JSON.parse(localStorage.getItem("selectSalesman"));
const initialUseLocalTime = JSON.parse(localStorage.getItem("useLocalTime"));
const initialitemsDescription = JSON.parse(localStorage.getItem("itemsDescription"));
const initialChosenLocale = JSON.parse(localStorage.getItem("chosenLocale"));

export const configs = {
  namespaced: true,
  state: {
    currencySymbol: null,
    apiImgBaseUrl: null,
    saleAreaBlocked: null,
    //Possible values:
    // [
    //   { name: "Rolo", value: "1" },
    //   { name: "A4", value: "2" },
    // ],
    printPageSize: initialPrintPageSize || "1",
    selectSalesman: initialSelectSalesman || false, // Possible values: true/false; used on the isNecessaryToIdentifySalesman getter of the Salesman store
    useLocalTime: initialUseLocalTime || false, // Possible values: true/false; emit sales with local computer time
    //Possible values:
    // [
    //   { name: "Curta", value: "1" },
    //   { name: "Completa", value: "2" },
    // ],
    itemsDescription: initialitemsDescription || "1",
    chosenLocale: initialChosenLocale || navigator.language.substring(0, 2).toLowerCase(),
  },
  actions: {
    setGeneralConfig({ commit }) {
      commit("setGeneralConfig");
    },
    setSaleAreaBlocked({ commit }, status) {
      commit("setSaleAreaBlocked", status);
    },
    setPrintPageSize({ commit }, value) {
      commit("setPrintPageSize", value);
    },
    setSelectSalesman({ commit }, value) {
      commit("setSelectSalesman", value);
    },
    setUseLocalTime({ commit }, value) {
      commit("setUseLocalTime", value);
    },
    setItemsDescription({ commit }, value) {
      commit("setItemsDescription", value);
    },
    setChosenLocale({ commit }, value) {
      commit("setChosenLocale", value);
    },
  },
  mutations: {
    setGeneralConfig(state) {
      state.currencySymbol = "€";
      state.apiImgBaseUrl = getApiBaseUrl() + "dimas/api/documents/getimage/?image=";
      state.saleAreaBlocked = false;
      Vue.$log.debug("Set General Config | State: " + JSON.stringify(state));
    },
    setSaleAreaBlocked(state, status) {
      state.saleAreaBlocked = status;
    },
    setPrintPageSize(state, value) {
      state.printPageSize = value;
      localStorage.setItem("printPageSize", JSON.stringify(state.printPageSize));
    },
    setSelectSalesman(state, value) {
      state.selectSalesman = value;
      localStorage.setItem("selectSalesman", JSON.stringify(state.selectSalesman));
    },
    setUseLocalTime(state, value) {
      state.useLocalTime = value;
      localStorage.setItem("useLocalTime", JSON.stringify(state.useLocalTime));
    },
    setItemsDescription(state, value) {
      state.itemsDescription = value;
      localStorage.setItem("itemsDescription", JSON.stringify(state.itemsDescription));
    },
    setChosenLocale(state, value) {
      state.chosenLocale = value;
      localStorage.setItem("chosenLocale", JSON.stringify(state.chosenLocale));
    },
  },
  getters: {
    currencySymbol: (state) => state.currencySymbol,
    apiImgBaseUrl: (state) => state.apiImgBaseUrl,
    saleAreaBlocked: (state) => state.saleAreaBlocked,
    printPageSize: (state) => state.printPageSize,
    selectSalesman: (state) => state.selectSalesman,
    useLocalTime: (state) => state.useLocalTime,
    itemsDescription: (state) => state.itemsDescription,
    chosenLocale: (state) => state.chosenLocale,
  },
};
