import Vue from "vue";
import Vuex from "vuex";

import { loader } from "./loader.module";
import { configs } from "./configs.module";
import { authentication } from "./authentication.module";
import { alert } from "./alert.module";
import { paymentTypes } from "./paymentTypes.module";
import { salesmen } from "./salesmen.module";
import { cashDrawers } from "./cashDrawers.module";
import { families } from "./families.module";
import { items } from "./items.module";
import { cart } from "./cart.module";
import { countries } from "./countries.module";
import { stores } from "./stores.module";
import { modelExtension } from "./modelExtension.module";
import { dataLog } from "./dataLog.module";
import { docTypes } from "./docTypes.module";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    authentication,
    alert,
    loader,
    configs,
    paymentTypes,
    salesmen,
    cashDrawers,
    families,
    items,
    cart,
    countries,
    stores,
    modelExtension,
    dataLog,
    docTypes,
  },
});
