import { familiesService } from "@/services";
import { getLocalDataBase } from "@/_helpers";
import Dexie from "dexie";

export const families = {
  namespaced: true,
  state: {
    selectedFamily: null,
    families: {}, // TODO: remove me
    familiesLastFetch: null,
  },
  actions: {
    // eslint-disable-next-line
    setConfiguration({ commit, dispatch }, { forceUpdate, autoSync }) {
      let startDate = new Date(Date.now()).toLocaleString("pt");
      //Initialize the value of the synccounter which will filter the information will get
      let synccounter = 1;
      //Open database and make it start functioning.
      let db = getLocalDataBase();
      db.open();

      //Use Dexie.spawn() or Dexie.async() to enable a synchronous-like programming style.
      var spawn = Dexie.spawn;
      // Interact With Database
      spawn(function* () {
        // Let's query the db
        //Getting the last information stored on the Entities table, to the Family Entity
        if (forceUpdate === 0) {
          var entityInfo = yield db.entities.where("entity").equals("family").toArray();
          if (entityInfo.length > 0) {
            synccounter = entityInfo.map((f) => f.synccounter)[0];
          }
        }
        //The "put" adds a new object or updates an existing object.
        //  I've chosen to use it instead of the "update", because the update only updates an existing object.
        //  Therefore, using the "update" it would have been necessary to verify if the "update" returned 1 and if not
        //  performing an insert.
        db.entities.put({
          entity: "family",
          startDate: startDate,
          endDate: null,
          nrSyncEntities: null,
          // synccounter: null,
        });

        //Accessing the backend API to get all the Families which match with the identified synccounter
        let result = yield familiesService.getAll(synccounter);

        //Iterating over the retrieved Families:
        //1 - building the dictionary which will add the Families to the database
        //2 - obtaining the maximum synccounter of the retrieved Families
        let maxSynccounter = 0;
        let bulkFamilies = [];
        for (var i = 0; i < result.length; i++) {
          //The aim of this flag is to identify if a family has subfamilies or not. And this information is used to draw
          // the catalog.
          //To define this flag two validations are performed:
          //1 - validating if the Family is identified as a subfamily of other Families being synchronized;
          //2 - validating if the Family is identified as a subfamily of other Families already included on the local database.
          var parentFamily = result.find((family) => family.ParentFamilyId === result[i].FamilyId);
          if (!parentFamily) {
            var subFamilies = yield db.families.where({ parentCode: result[i].Code, status: 1 }).toArray();
            parentFamily = subFamilies.length;
          }
          bulkFamilies.push({
            id: result[i]["Id"],
            code: result[i]["FamilyId"],
            familyLevel: result[i]["FamilyLevel"],
            parentCode: result[i]["ParentFamilyId"],
            status: result[i]["Status"],
            subFamilies: !!parentFamily,
            synccounter: result[i]["synccounter"],
            data: result[i],
          });

          if (result[i].synccounter > maxSynccounter) {
            maxSynccounter = result[i].synccounter;
          }
        }

        //1 - Adding all given objects to the store.
        //2 - updating the Entities table, setting the new synccounter for the Entity Families and setting the new
        // last sync date
        if (bulkFamilies.length > 0) {
          db.families
            //  If an object with the same primary key already exists, it will be replaced with the given object.
            //  If it does not exist, it will be added.
            .bulkPut(bulkFamilies)
            .then(function () {
              db.entities.put({
                entity: "family",
                startDate: startDate,
                endDate: new Date(Date.now()).toLocaleString("pt"),
                nrSyncEntities: bulkFamilies.length,
                synccounter: maxSynccounter,
              });
              commit("setFamiliesLastFetch", Date.now());
            })
            // eslint-disable-next-line
            .catch(Dexie.BulkError, function (e) {
              // Explicitly catching the bulkAdd() operation makes those successful
              // additions commit despite that there were errors.
            });
        } else {
          db.entities.put({
            entity: "family",
            startDate: startDate,
            endDate: new Date(Date.now()).toLocaleString("pt"),
            nrSyncEntities: 0,
            synccounter: synccounter,
          });
        }
        //Setting the synchronization to run 3 minutes after the synchronization is finished
        if (autoSync === 1) {
          setTimeout(function () {
            let autoSync = 1;
            let forceUpdate = 0;
            dispatch("setConfiguration", { forceUpdate, autoSync });
          }, 180000);
        }

        // eslint-disable-next-line
      }).catch(function (err) {
        // Catch any error event or exception
        console.error(err.stack || err);
      });
    },
    setSelectedFamily({ commit }, familyId) {
      commit("setSelectedFamily", familyId);
    },

    getAll: () => {
      let db = getLocalDataBase();

      return db.families.where("status").equals(1);
    },
    getMain() {
      let db = getLocalDataBase();

      return db.families.where({ familyLevel: 1, status: 1 }).toArray();
      // return db.families.where('[familyLevel+status]').equals([1, 1])
    },
    get(params, id) {
      let db = getLocalDataBase();

      return db.families.where("id").equals(id).toArray();
    },
    getChildren(params, { code, offset, limit }) {
      let db = getLocalDataBase();
      let families = db.families.where({ parentCode: code, status: 1 });
      if (offset !== undefined && limit !== undefined) families = families.offset(offset).limit(limit);

      return families.toArray();
    },
  },
  mutations: {
    setSelectedFamily: (state, family) => {
      state.selectedFamily = family;
    },
    setFamiliesLastFetch: (state, lastFetch) => {
      state.familiesLastFetch = lastFetch;
    },
  },
  getters: {
    // getAll: () => {
    //   let db = getLocalDataBase();
    //   db.open();
    //   return db.families.where("status").equals(1);
    // },
    // getMain() {
    //   let db = getLocalDataBase();
    //   db.open();
    //   return db.families.where({ familyLevel: 1, status: 1 }).toArray();
    //   // return db.families.where('[familyLevel+status]').equals([1, 1])
    // },
    // get: () => (id) => {
    //   let db = getLocalDataBase();
    //   db.open();
    //
    //   return db.families.where({ parentId: id, status: 1 }).toArray();
    // },
    getSelectedFamily: (state) => {
      return state.selectedFamily;
    },
    getFamiliesLastFetch: (state) => {
      return state.familiesLastFetch;
    },
  },
};
