import { cashDrawersService } from "@/services";
import { getLocalDataBase } from "@/_helpers";
import Dexie from "dexie";
import { store } from "./index";

export const cashDrawers = {
  namespaced: true,
  state: {
    cashDrawers: {},
  },
  actions: {
    // eslint-disable-next-line
    setConfiguration({ commit, dispatch }, { forceUpdate, autoSync }) {
      let startDate = new Date(Date.now()).toLocaleString("pt");
      //Initialize the value of the synccounter which will filter the information will get
      let synccounter = 1;
      //Open database and make it start functioning.
      let db = getLocalDataBase();
      db.open();

      //Use Dexie.spawn() or Dexie.async() to enable a synchronous-like programming style.
      var spawn = Dexie.spawn;
      // Interact With Database
      spawn(function* () {
        // Let's query the db
        //Getting the last information stored on the Entities table, to the Family Entity
        if (forceUpdate === 0) {
          var entityInfo = yield db.entities.where("entity").equals("cashDrawer").toArray();
          if (entityInfo.length > 0) {
            synccounter = entityInfo.map((f) => f.synccounter)[0];
          }
        }
        //The "put" adds a new object or updates an existing object.
        //  I've chosen to use it instead of the "update", because the update only updates an existing object.
        //  Therefore, using the "update" it would have been necessary to verify if the "update" returned 1 and if not
        //  performing an insert.
        db.entities.put({
          entity: "cashDrawer",
          startDate: startDate,
          endDate: null,
          nrSyncEntities: null,
          synccounter: synccounter,
        });

        //Accessing the backend API to get all the Cash Drawers which match with the identified synccounter
        let result = yield cashDrawersService.getAll(synccounter);

        //Iterating over the retrieved Cash Drawers:
        //1 - building the dictionary which will add the Cash Drawers to the database
        //2 - obtaining the maximum synccounter of the retrieved Cash Drawers
        let maxSynccounter = 0;
        let bulkCashDrawers = [];
        for (var i = 0; i < result.length; i++) {
          bulkCashDrawers.push({
            id: result[i]["Id"],
            tillId: result[i]["TillId"],
            description: result[i]["Description"],
            isOpen: result[i]["IsOpen"] ? 1 : 0,
            status: result[i]["Status"],
            synccounter: result[i]["synccounter"],
            data: result[i],
          });

          if (result[i].synccounter > maxSynccounter) {
            maxSynccounter = result[i].synccounter;
          }
        }

        //1 - Adding all given objects to the store.
        //2 - updating the Entities table, setting the new synccounter for the Entity Cash Drawers and setting the new
        // last sync date
        if (bulkCashDrawers.length > 0) {
          db.cashDrawers
            //  If an object with the same primary key already exists, it will be replaced with the given object.
            //  If it does not exist, it will be added.
            .bulkPut(bulkCashDrawers)
            .then(function () {
              db.entities.put({
                entity: "cashDrawer",
                startDate: startDate,
                endDate: new Date(Date.now()).toLocaleString("pt"),
                nrSyncEntities: bulkCashDrawers.length,
                synccounter: maxSynccounter,
              });
            })
            // eslint-disable-next-line
            .catch(Dexie.BulkError, function (e) {
              // Explicitly catching the bulkAdd() operation makes those successful
              // additions commit despite that there were errors.
            });
        } else {
          db.entities.put({
            entity: "cashDrawer",
            startDate: startDate,
            endDate: new Date(Date.now()).toLocaleString("pt"),
            nrSyncEntities: 0,
            synccounter: synccounter,
          });
        }

        //Setting the synchronization to run 3 minutes after the synchronization is finished
        if (autoSync === 1) {
          setTimeout(function () {
            let autoSync = 1;
            let forceUpdate = 0;
            dispatch("setConfiguration", { forceUpdate, autoSync });
          }, 180000);
        }

        // eslint-disable-next-line
      }).catch(function (err) {
        // Catch any error event or exception
        console.error(err.stack || err);
      });
    },

    setCashDrawerOpen: ({ commit }, cashDrawerId) => {
      commit("setCashDrawerOpen", cashDrawerId);
    },

    setCashDrawerClose: ({ commit }, cashDrawerId) => {
      commit("setCashDrawerClose", cashDrawerId);
    },

    setCashDrawerDefault: ({ commit }, cashDrawerId) => {
      commit("setCashDrawerDefault", cashDrawerId);
    },

    /**
     * This function accesses the API to determine if there is any CashDrawer opened.
     * The information Stored on the IndexedDB is not enough, since it may not be up to date, since
     * setting a CashDrawer Open does not change its synccounter.
     * If there are open CashDrawers, the IndexedDB is updated, setting the attribute isOpen to 1.
     * Note: I tried to define this as a Getter, however it did not work.
     * The GETTER was not called, because no changes were applied to the Store.
     * @returns {Promise<unknown>}
     */
    isAnyCashDrawerOpen: async () => {
      return new Promise((resolve, reject) => {
        cashDrawersService.getOpen().then(
          (result) => {
            let db = getLocalDataBase();
            db.open();
            //As caixas marcadas como abertas no IndexedDB são marcadas como fechadas
            store.dispatch("cashDrawers/getOpenCashDrawers").then((resultg) => {
              let cashDrawers = resultg;
              for (var i = 0; i < cashDrawers.length; i++) {
                db.cashDrawers.update(cashDrawers[i].id, { isOpen: 0 });
              }

              if (result.count === 0) {
                resolve(false);
              } else {
                //As caixas que a API identificou como abertas, são marcadas como abertas no IndexedDB
                for (var j = 0; j < result.results.length; j++) {
                  db.cashDrawers.update(result.results[j]["Id"], { isOpen: 1 });
                }
                resolve(true);
              }
            });
          },
          (error) => {
            reject(error);
          }
        );
      });
    },
    getAll: () => {
      let db = getLocalDataBase();

      return db.cashDrawers.where("status").equals(1).toArray();
    },
    /** TODO: comentar
     * @deprecated
     * @returns {PromiseExtended<Array<T>>}
     */
    getOpenCashDrawers: () => {
      let db = getLocalDataBase();

      return db.cashDrawers.where({ isOpen: 1, status: 1 }).toArray();
    },
    /**
     * TODO: comentar
     * @returns {Promise<Array<T>>}
     */
    async getDefaultCashDrawers() {
      let db = getLocalDataBase();

      let results = null;
      let nCashdrawers = null;

      nCashdrawers = await db.cashDrawers.toArray();
      results = await db.cashDrawers.where({ isOpen: 1, status: 1, default: 1 }).toArray();
      if (results.length === 0 && nCashdrawers.length === 1) {
        results = db.cashDrawers.where({ isOpen: 1, status: 1 }).toArray();
      }

      // return db.cashDrawers.where({ isOpen: 1, status: 1, default: 1}).toArray();
      return results;
    },
  },
  mutations: {
    // eslint-disable-next-line
    setCashDrawerOpen: (state, cashDrawerId) => {
      let db = getLocalDataBase();

      db.cashDrawers.where({ default: 1 }).modify({ default: 0 });

      db.cashDrawers.update(cashDrawerId, { isOpen: 1 });
      db.cashDrawers.update(cashDrawerId, { default: 1 });
    },
    // eslint-disable-next-line
    setCashDrawerClose: (state, cashDrawerId) => {
      let db = getLocalDataBase();

      db.cashDrawers.update(cashDrawerId, { isOpen: 0 });
      db.cashDrawers.update(cashDrawerId, { default: 0 });
    },
    // eslint-disable-next-line
    setCashDrawerDefault: (state, cashDrawerId) => {
      let db = getLocalDataBase();

      db.cashDrawers.where({ default: 1 }).modify({ default: 0 });

      db.cashDrawers.update(cashDrawerId, { default: 1 });
    },
  },
  getters: {
    // getAll: () => {
    //   let db = getLocalDataBase();
    //   db.open();
    //   return db.cashDrawers.where("status").equals(1).toArray();
    // },
    // getOpenCashDrawers: () => {
    //   let db = getLocalDataBase();
    //   db.open();
    //   return db.cashDrawers.where({ isOpen: 1, status: 1 }).toArray();
    // },
  },
};
