import { modelExtensionServices } from "@/services";
import { getLocalDataBase } from "@/_helpers";
import Dexie from "dexie";

export const modelExtension = {
  namespaced: true,
  actions: {
    // eslint-disable-next-line
    setConfiguration({ commit, dispatch }, { forceUpdate, autoSync }) {
      let startDate = new Date(Date.now()).toLocaleString("pt");
      //Initialize the value of the synccounter which will filter the information will get
      let synccounter = 1;
      //Open database and make it start functioning.
      let db = getLocalDataBase();
      db.open();

      //Use Dexie.spawn() or Dexie.async() to enable a synchronous-like programming style.
      var spawn = Dexie.spawn;
      // Interact With Database
      spawn(function* () {
        // Let's query the db
        //Getting the last information stored on the Entities table, to the Payment Method's Entity
        // if (forceUpdate === 0) {
        //   var entityInfo = yield db.entities.where("entity").equals("modelextension").toArray();
        //   if (entityInfo.length > 0) {
        //     synccounter = entityInfo.map((f) => f.synccounter)[0];
        //   }
        // }
        //The "put" adds a new object or updates an existing object.
        //  I've chosen to use it instead of the "update", because the update only updates an existing object.
        //  Therefore, using the "update" it would have been necessary to verify if the "update" returned 1 and if not
        //  performing an insert.
        db.entities.put({
          entity: "modelextension",
          startDate: new Date().toISOString(),
          endDate: null,
          nrSyncEntities: null,
          synccounter: synccounter,
        });

        //Accessing the backend API to get all the Countries which match with the identified synccounter
        let result = yield modelExtensionServices.getAllModelExtensionFields(synccounter);

        //Iterating over the retrieved Countries:
        //1 - building the dictionary which will add the Countries to the database
        //2 - obtaining the maximum synccounter of the retrieved Countries
        let maxSynccounter = 0;
        let bulkModelExtensionFields = [];
        for (var i = 0; i < result["results"].length; i++) {
          bulkModelExtensionFields.push({
            id: result["results"][i]["Id"],
            code: result["results"][i]["Code"],
            model: result["results"][i]["Model"],
            name: result["results"][i]["Name"],
            required: result["results"][i]["Required"],
            type: result["results"][i]["Type"],
            data: result["results"][i],
          });

          // if (result["results"][i].synccounter > maxSynccounter) {
          //   maxSynccounter = result["results"][i].synccounter;
          // }
        }

        //1 - Adding all given objects to the store.
        //2 - updating the Entities table, setting the new synccounter for the Entity Countries and setting the new
        // last sync date
        if (bulkModelExtensionFields.length > 0) {
          db.extensionFields
            //  If an object with the same primary key already exists, it will be replaced with the given object.
            //  If it does not exist, it will be added.
            .bulkPut(bulkModelExtensionFields)
            .then(function () {
              db.entities.put({
                entity: "modelextension",
                startDate: startDate,
                endDate: new Date(Date.now()).toLocaleString("pt"),
                nrSyncEntities: bulkModelExtensionFields.length,
                synccounter: maxSynccounter,
              });
            })
            // eslint-disable-next-line
            .catch(Dexie.BulkError, function (e) {
              // Explicitly catching the bulkAdd() operation makes those successful
              // additions commit despite that there were errors.
            });
        } else {
          db.entities.put({
            entity: "modelextension",
            startDate: startDate,
            endDate: new Date(Date.now()).toLocaleString("pt"),
            nrSyncEntities: 0,
            synccounter: synccounter,
          });
        }

        //Setting the synchronization to run 3 minutes after the synchronization is finished
        if (autoSync === 1) {
          setTimeout(function () {
            let autoSync = 1;
            let forceUpdate = 0;
            dispatch("setConfiguration", { forceUpdate, autoSync });
          }, 180000);
        }

        // eslint-disable-next-line
      }).catch(function (err) {
        // Catch any error event or exception
        console.error(err.stack || err);
      });
    },
    getExtensionFieldForModel(params, model) {
      let db = getLocalDataBase();

      return db.extensionFields.where("model").equals(model).toArray();
    },
  },
  getters: {
    // getExtensionFieldForModel: () => (model) => {
    //   let db = getLocalDataBase();
    //
    //   return db.extensionFields.where("model").equals(model).toArray();
    // },
  },
};
