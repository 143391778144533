import Vue from "vue";
import "./plugins/vuejs-logger";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import router from "./router";
import { store } from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/axios";
import "./registerServiceWorker";
import "./plugins/datadog";

import App from "./App.vue";

Vue.use(Loading);
Vue.config.productionTip = false;

Vue.config.errorHandler = (err, instance, info) => {
  // err: error trace
  // vm: component in which error occurred
  // info: Vue specific error information such as lifecycle hooks, events etc.

  let actionType = "Global error";
  let actionValue = instance["$vnode"]["tag"];
  let actionData = { info: `Error: ${err.toString()}\nInfo: ${info}` };
  store.dispatch("dataLog/setData", {
    actionType,
    actionValue,
    actionData,
  });
};

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
