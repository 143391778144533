import Vue from "vue";
import { getApiBaseUrl } from "./utils";

export function setAxiosDefaultHeaders() {
  let user = JSON.parse(localStorage.getItem("user"));

  if (user && user.access) {
    Vue.axios.defaults.headers["Authorization"] = "JWT " + user.access;
  } else {
    delete Vue.axios.defaults.headers["Authorization"];
    delete Vue.axios.defaults.headers["Authorization"];
  }
}
export function setAxiosDefaultUrl() {
  Vue.axios.defaults.baseURL = getApiBaseUrl();
}
