import { pt } from "vuetify/lib/locale";

export default {
  ...pt,

  languageName: "PT - Português",
  languageFlag: "pt",

  //Erros
  errorCreditNotesIssue: "Não é possível emitir mais notas de crédito para a Fatura",
  errorGettingCountries: "Erro a obter Países",
  errorGettingCustomerDetail: "Getting the Customer Detail",
  errorIdAvailableCampaigns: "Não foi possível identificar Campanhas Disponíveis",
  errorIdAvailableVouchers: "Não foi possível identificar Vales Disponíveis.",
  errorInfoEmployee: "Erro a obter informação do Empregado",
  errorInvalidLoyaltyCard: "Cartão de Fidelização Inválido",
  errorNoSaleItems: "Não há artigos na Venda",
  errorRegisterBudget: "Não foi possível registar o Orçamento.",
  errorRegisterClient: "Não foi possível registar o Cliente.",
  errorRegisterSale: "Não foi possível registar a venda.",
  errorRegisterSessionMovement: "Não foi possível registar o Movimento de Sessão.",
  errorSearchValueNotIndicated: "Não foi indicado um valor para pesquisa.",
  errorStoreZone: "Erro a obter Zonas da Loja",
  errorValidatingCredentials: "Não foi possível validar o Empregado e palavra-passe indicados.",
  itemNotFoundMessage: "Erro ao Adicionar Artigo à Venda. Artigo não encontrado.",
  catalogSearchResults: {
    errorIdArticles: "Não foi possível identificar Artigos de acordo com a pesquisa.",
    errorNoSearch: "Não foi indicado um valor para pesquisa.",
  },
  errorStockExit: "Erro a obter saída justificada.",
  notFoundStockExit: "Saída Justificada não encontrada.",
  invalidItemStockExit: "Foram encontrados artigos não sincronizados na saída justificada.",
  errorCancelDocument: "Não é possível a Anulação do Documento",
  errorItemToReturn: "Não é possível adicionar artigos a uma Devolução.",
  errorItemInfoExtra: "Venda sem Artigos não permite adicionar artigos Informativos/Extra.",

  //Legendas, Botões e Opcões
  add: "Adicionar",
  addressClient: "Endereço",
  backoffice: "Backoffice",
  billingData: "Dados faturação",
  campaigns: "Campanhas",
  cancel: "Cancelar",
  cancelAndPrint: "Suspender e Imprimir",
  cancelDocument: "Cancelar documento",
  fromStockExit: "Venda a partir de Saída Justificada",
  cashDrawers: "Caixas",
  catalog: "Catálogo",
  change: "Troco",
  clean: "Limpar",
  client: "Cliente",
  close: "Fechar",
  code: "Código",
  consultSales: "Consultar Venda",
  consultSalesDescription: "Consulte documentos de venda emitidos",
  consultSuspended: "Consulte documentos em suspenso",
  country: "País",
  customerLoyaltyCard: "Cartão de Fidelização",
  customerMobileTelephone: "Nr. Telemóvel",
  customerSearchMessage: "Pesquise pelo Cartão de Fidelização, NIF ou Número de Telemóvel",
  date: "Data",
  discount: "Desconto",
  discountShort: "Desc.%",
  discountType: "Tipo Desconto",
  download: "Download",
  email: "E-mail",
  end: "Finalizar",
  finalClient: "Cliente final",
  globalDiscount: "Desconto Global",
  goBackToLogin: "Voltar para o login",
  identifier: "Identificador", //
  location: "Localidade", //
  logout: "LogOut",
  loyalty: "Campanhas Fidelização",
  loyaltyCardVoucherNumberMessage: "Cartão Fidelização/Nr. Vale",
  moneyRemaining: "Faltam",
  more: "Mais",
  name: "Nome",
  newClient: "Novo Cliente",
  numberOfCopies: "Quantidade Impressão",
  obs: "Observações",
  offerDocument: "Talão Oferta",
  ok: "Ok",
  open: "Abrir",
  openCashDrawer: "Abrir Caixa",
  password: "Palavra-passe",
  pattyCash: "Fundo de Maneio",
  pay: "Pagar",
  payment: "Pagamento",
  paymentMethod: "Método de Pagamento",
  paymentMethods: "Métodos de Pagamento",
  percentage: "Percentagem",
  points: "Pontos",
  postalCode: "Código Postal",
  print: "Imprimir",
  quantity: "Quantidade",
  reports: "Relatórios",
  resumeSale: "Retomar",
  return: "Devolver",
  returnDocument: "Devolução",
  sale: "Venda",
  saleDocumentDate: "Data Documento",
  sales: "Vendas",
  salesDescription: "Crie novos documentos de venda",
  salesmenName: "Atendido por",
  salesmenSessionClose: "Fechar Sessão",
  salesmenSessionOpen: "Abrir Sessão",
  salesSub: "Visão dos totais de vendas, totais de IVA e formas de pagamento",
  save: "Guardar",
  savedSales: "Sem Vendas Suspensas",
  search: "Pesquisar",
  searchDocument: "Pesquisar Nr. Documento",
  selectDate: "Selecionar Data",
  selectSalesman: "Selecionar Empregado",
  sessions: "Sessões",
  sessionsProcedure: "Proceda à abertura e fecho de sessões",
  start: "Início",
  submit: "Submeter",
  suspendedSales: "Vendas Suspensas",
  taxNumber: "NIF",
  total: "Total",
  unitPrice: "Preço Unitário",
  updatePassword: "Atualizar Password",
  validate: "Validate",
  valuePay: "Valor",
  voucherCode: "Código do Vale",

  //Mensagens de Feedback e Confirmação
  appliedVatExempt: "IVA Isento Aplicado",
  applyVatExempt: "Aplicar Isenção IVA",
  cashDrawersProcedure: "Proceda à Abertura e Fecho de Caixas",
  confirmConfirmCancelDocumentMessage: "Esta é uma operação irreversível.",
  confirmConfirmCancelDocumentTitle: "Tem a certeza que pretende Anular a Venda?",
  confirmLogout: "Encerrar a Sessão?",
  msgAreYouSure: "Tem a certeza?",
  msgAreYouSureRemoveItems: "Tem a certeza que pretende remover todos os Artigos da Venda?",
  msgAreYouSureSuspendSale: "Tem a certeza que pretende Suspender a Venda?",
  msgCheckInstructions: "Verifique as instruções no terminal de pagamento",
  msgCloseSessionSuccess: "Fecho de Sessão realizada com Sucesso.",
  msgContentNotAvailable: "Conteúdo não disponível de momento.",
  msgDetailsInvoice:
    "Introduza os seus dados se pretender fatura com dados fiscais ou clique em Pagamento para avançar",
  msgExpiredVoucher: "O Talão de Reembolso expirou em ",
  msgInvalidSlip: "Identificador do Talão de Reembolso inválido.",
  msgOpenEmployeeSessions: "É necessário proceder à abertura de Sessões de Empregados",
  msgOpenSessionSuccess: "Abertura de Sessão realizada com Sucesso.",
  msgSuspendSaleAlert: "Quer suspender esta venda? Se não suspender, perderá os artigos da venda!",
  msgUsedVoucher: "O Talão de Reembolso já foi utilizado.",
  msgCancelDocument: "Anulação do Documento realizada com Sucesso.",
  pageNotFound: "Página não encontrada",
  removeVatExempt: "Remover Inseção IVA",
  startPurchase: "Inicie a sua compra",
  tryAgain: "Por favor tente novamente.",
  warningNoPermissionsTo: "Sem Permissões para aceder a '",
  welcome: "Bem-Vindo",
  cashDrawersPage: {
    activateSalesBox: "Ativar Caixa de Venda",
    checkCashMov: "Consulta de Movimentos de Caixa",
    closeCashRegister: "Fecho de caixa",
    consult: "Consultar",
    getData: "Get Cash Drawer Data",
    includesPattyCash: "Valor do Numerário inclui Fundo de Maneio?",
    initialPattyCash: "Fundo de maneio inicial",
    msgCloseSuccess: "Fecho de Caixa realizado com Sucesso",
    msgMovemNotAvailable: "Não foi possível registar o Movimento de Caixa.",
    msgOpenClose: "É necessário proceder à abertura/selecção de Caixa antes de realizar qualquer operação",
    msgOpenSuccess: "Abertura de Caixa realizada com Sucesso",
    openCashRegister: "Abertura de caixa",
    pattyCashNextDay: "Fundo Maneio Próx. Dia",
  },
  cashDrawersSearchPage: {
    gettingResults: "A obter resultados de pesquisa",
  },
  noResults: "Sem Resultados",
  //Validações
  addressMax200: "Endereço deve ter no máximo 200 caracteres",
  countryMax2: "País deve ter no máximo 2 caracteres",
  errorInvalidEmail: "E-mail Inválido",
  errorInvalidMobile: "Nr. Telemóvel inválido",
  errorInvalidTIN9: "NIF deve ter 9 dígitos",
  errorInvalidTIN: "NIF Inválido",
  expiredPassword: "Palavra-passe Expirada",
  expiredPasswordMessage: "É necessário proceder à alteração da sua palavra-passe.",
  firstAndLastName: "Primeiro e Último Nome",
  locationMax100: "Localidade deve ter no máximo 100 caracteres",
  mandatoryField: "Campo obrigatório",
  nameMax20: "Nome deve ter no máximo 20 caracteres",
  noStock: "Sem stock",
  passwordRequired: "Palavra-passe requerida",
  paymentMethodDoesNotAllowChange: "O Método de pagamento não permite troco",
  postalCodeMax20: "Cód. Postal deve ter no máximo 20 caracteres",
  usernameRequired: "Nome de Utilizador requerido",
  value100: "Valor deve começar por 100",
  value10to20: "Valor deve ter entre 10 e 20 caracteres",
  valueAbove0: "Valor deve ser igual ou superior a 0",
  valuesBetween0to100: "Valores válidos: entre 0 e 100",
  valuesBetween0toX: "Valores válidos: entre 0 e ",
  itemsMenuLevelsCardPage: {
    allowed: "Apenas são permitidos",
    itemsInLevel: "artigos no nível",
    maxChoices: "Nr. Máximo de Escolhas",
    summary: "Resumo",
  },

  //Páginas específicas:
  //--Página de Administração
  admin: {
    administration: "Administração",
    //1º cartão
    deviceConfiguration: "Configuração Dispositivos",
    printer: "Impressora",
    scale: "Balança",
    cashDrawer: "Caixa",
    virtualKeyboard: "Teclado virtual",
    //2º cartão
    generalSettings: "Configurações gerais",
    productDescription: "Descrição dos Artigos",
    employeeSelection: "Obrigatório selecionar Empregado",
    sizePrintDoc: "Tamanho Impressão Documentos Venda",
    receiptRoll: "Rolo",
    a4: "A4",
    short: "Curta",
    complete: "Completa",
    useLocalTime: "Utilizar hora local na emissão das faturas",
    //3º cartão
    dataSynchronization: "Sincronizar informação",
    updateMessage: "Selecione as entidades para atualizar com a informação mais recente disponível.",
    //options
    families: "Famílias",
    product: "Artigos",
    cashier: "Empregados",
    cashRegister: "Caixas",
    infoNotPossible: "Não foi possível obter a informação de ",
    syncMessageSuccess: "Sincronização de Informação realizada com Sucesso.",
  },
  authentication: {
    login_error: "Não foi possível validar o utilizador e palavra-passe indicados. Pfv tente novamente.",
    forbidden: "Sem permissão",
  },
};
