import { store } from "../store";
import { round2Decimals, displayWithCents } from "@/_helpers";
import Vue from "vue";
import { v4 as uuidv4 } from "uuid";
import vuetify from "../plugins/vuetify";

export const cart = {
  namespaced: true,
  state: {
    activeCart: {
      items: [],
      //Identification of the Cart's type. Possible values: sales, return, returnTotal
      type: "sale",
      refDocument: null,
      vatExempt: false,
      //@modified ana.castro 2022.12.05 SAFO-47
      // salesPricesRange: { code: null, verbose: null },
      zone: { code: null, verbose: null, salespricerange: 1, selfexpenditure: 0 },
      taxNumber: null,
      loyaltyCardId: null,
      customerName: null,
      //Unique identifier randomly generated for each Sale's Document.
      hash: null,
    },
    /**
     * customer: {
     *  taxNumber: ,
     *  loyaltyCardId: ,
     *  name:,
     * }
     * dateHour: "2022-04-22 14:51:16",
     * extraIdentifier: "OR SU03/12",
     * items: [],
     * totalAmount: "2.10"
     * vatExempt: false
     * salesPricesRange: 1
     */
    savedCarts: [],
    checkoutCartInProgress: false,
    checkoutSubmitAvailable: false,
    checkoutCartSalesman: undefined,
    displayCheckoutCartSalesman: false,
    appliedLoyaltyCampaigns: [],
  },
  mutations: {
    /**
     * Setting the store with the Cart's definition which is stored on the Local Storage
     * @param state
     */
    initialiseStore: (state) => {
      if (localStorage.getItem("cart.activeCart")) {
        state.activeCart = JSON.parse(localStorage.getItem("cart.activeCart"));
      }
      if (localStorage.getItem("cart.savedCarts")) {
        state.savedCarts = JSON.parse(localStorage.getItem("cart.savedCarts"));
      }
    },
    /**
     * Adding a new Item to the Shopping Cart, which is stored on the local storage
     * @param state
     * @param item: the Item's identifier
     * @param quantity
     */
    addToCart: (state, { item, quantity }) => {
      let cartProduct = null;
      // If we don't receive a quantity, assume 1
      quantity = quantity ?? 1;

      // Find the item in the cart list. The idea is to reuse an existing Cart entry instead of creating a new one.
      // However, this rule does not apply to Items of type "Menu" or Items of type "Open"
      if (!item.menu_items && !item.extra_info) {
        cartProduct = state.activeCart.items.find((cartItem) => cartItem.item_id === item.id);
      }

      if (cartProduct) {
        // Item already present in the cart, so increase the quantity
        cartProduct.quantity += quantity;
      } else {
        let itemUnitPrice = store.getters["cart/itemUnitPrice"](item);
        let itemNetPrice = store.getters["cart/itemNetUnitPrice"](item);
        let itemOrder = 1;
        if (state.activeCart.items.length > 0) {
          if (state.activeCart.items[state.activeCart.items.length - 1].order) {
            itemOrder = state.activeCart.items[state.activeCart.items.length - 1].order + 1;
          }
        }

        state.activeCart.items.push({
          // Item newly added to cart
          item_id: item.id,
          item_code: item.code,
          item_name: item.description,
          item_price: itemUnitPrice,
          item_net_price: itemNetPrice,
          item_unit_price: itemUnitPrice,
          item_image: item.data ? item.data.image : item.image,
          item_family: item.family,
          item_extra_info: item.extra_info,
          quantity: quantity,
          discount: { value: 0, type: null }, //Guarda o valor do Desconto de acordo com o Tipo de Desconto.
          discount_loyalty: { value: 0, type: null }, //Guarda o valor e o tipo do Desconto que advém de Campanhas de Fidelização
          discount_value_total: 0, //Guarda o valor do Desconto SEMPRE em Valor.
          menu_items: item.menu_items,
          components: item.components,
          order: itemOrder,
        });
      }
      // Setting the new state of the cart to the Local Storage
      localStorage.setItem("cart.activeCart", JSON.stringify(state.activeCart));
    },
    /**
     * A unit of the given Item is removed from the Shopping Cart. If the final quantity of the Item is zero,
     * the entrance of the Item is completely removed from the Shopping Cart.
     * @param state
     * @param item: the Item's identifier
     *
     * @deprecated
     */
    removeFromCart: (state, item) => {
      // Find the item in the cart list
      let cartProduct = state.activeCart.items.find((cartItem) => cartItem.item_id === item.id);
      // Find the item index in the cart list
      let cartProductIndex = state.activeCart.items.findIndex((cartItem) => cartItem.item_id === item.id);

      // Decrementing the Item's quantity
      cartProduct.quantity--;

      // Deleting the Item from the cart since its quantity is Zero
      if (cartProduct.quantity <= 0) {
        state.activeCart.items.splice(cartProductIndex, 1);
        //
        if (state.activeCart.items.length === 0) {
          let activeCartZone = state.activeCart["zone"];

          if (localStorage.getItem("cart.activeCart")) {
            state.activeCart = {
              items: [],
              type: "sale",
              taxNumber: null,
              refDocument: null,
              loyaltyCardId: null,
              vatExempt: false,
              zone: activeCartZone,
            };
          }
        }
      } else {
        // Setting the new state of the cart to the Local Storage
        localStorage.setItem("cart.activeCart", JSON.stringify(state.activeCart));
      }
    },
    /**
     * The given Item is excluded from the Shopping Cart.
     * @param state
     * @param item: the Item's identifier
     * @param itemOrder
     */
    deleteFromCart: (state, { itemId, itemOrder }) => {
      let cartProductIndex = state.activeCart.items.findIndex(
        (cartItem) => cartItem.item_id === itemId && cartItem.order === itemOrder
      );
      // Remove the item from the cart
      state.activeCart.items.splice(cartProductIndex, 1);

      //
      if (state.activeCart.items.length === 0) {
        let activeCartZone = state.activeCart["zone"];

        if (localStorage.getItem("cart.activeCart")) {
          state.activeCart = {
            items: [],
            type: "sale",
            taxNumber: null,
            refDocument: null,
            loyaltyCardId: null,
            zone: activeCartZone,
          };
        }
      }

      // Setting the new state of the cart to the Local Storage
      localStorage.setItem("cart.activeCart", JSON.stringify(state.activeCart));
    },
    editElementInCart: (
      state,
      {
        itemId,
        itemCode,
        itemUnitPrice,
        itemDiscount,
        itemDiscountType,
        itemDiscountLoyalty,
        itemDiscountLoyaltyType,
        quantity,
        itemName,
        itemMenu,
        itemComponent,
        itemOrder,
      }
    ) => {
      // Find the item in the cart list
      let cartProduct = null;
      if (!itemMenu) {
        cartProduct = state.activeCart.items.find(
          (cartItem) => cartItem.item_id === itemId && cartItem.order === itemOrder
        );
      }
      //To store the line's total value without applying any discount.
      // This will be used to guarantee that the Discount's value will never be higher than the line's total value.
      let itemTotalPriceWithoutDiscount = itemUnitPrice * quantity;
      //Initializing the Discount's value to Zero. This variable will accumulate
      //  the various discounts (line discounts + loyalty campaigns discounts) which might be applied to an Item.
      let itemDiscountValue = 0;
      //Initializing the variables which will contain the definition of the discounts:
      //  {value: X, type: Y}
      let localItemDiscount = { value: 0, type: null };
      let localItemDiscountLoyalty = { value: 0, type: null };

      //If the Item exists in the Cart, the existing Discounts are assigned to the previous initialized variables.
      if (cartProduct) {
        localItemDiscount = cartProduct.discount;
        localItemDiscountLoyalty = cartProduct.discount_loyalty;
      }

      //Defining the new Line Discounts
      if (itemDiscount || itemDiscount === 0) {
        if (itemDiscountType === "percentage") {
          localItemDiscount = {
            value: Math.min(itemDiscount, 100),
            type: itemDiscountType,
          };
        } else {
          localItemDiscount = {
            value: Math.min(itemDiscount, itemTotalPriceWithoutDiscount),
            type: itemDiscountType,
          };
        }
      }

      //Defining the new Loyalty Campaign Discounts
      if (itemDiscountLoyalty || itemDiscountLoyalty === 0) {
        if (itemDiscountLoyalty === "percentage") {
          localItemDiscountLoyalty = {
            value: Math.min(itemDiscountLoyalty, 100),
            type: itemDiscountLoyaltyType,
          };
        } else {
          localItemDiscountLoyalty = {
            value: Math.min(itemDiscountLoyalty, itemTotalPriceWithoutDiscount),
            type: itemDiscountLoyaltyType,
          };
        }
      }

      Vue.$log.debug("The Item's total price without discount is", itemTotalPriceWithoutDiscount);

      //Calculating the Discount in Value
      //When this value is defined, it means that a Line's Discount exists.
      if (localItemDiscount.type) {
        Vue.$log.debug(
          "A discount was applied to the line.",
          "The Discount's type is '",
          localItemDiscount.type,
          "' and its value is ",
          localItemDiscount.value
        );
        if (localItemDiscount.type === "percentage") {
          itemDiscountValue += parseFloat(((itemUnitPrice * quantity * localItemDiscount.value) / 100).toFixed(2));
        } else {
          itemDiscountValue += localItemDiscount.value;
        }
      }
      Vue.$log.debug("After validating the line's discount, the VALUE of the discount is ", itemDiscountValue);

      //Calculating the Discount in Value
      //When this value is defined, it means that a Loyalty Campaign Discount exists.
      if (localItemDiscountLoyalty.type) {
        Vue.$log.debug(
          "A Loyalty Discount was applied.",
          "The Loyalty Discount's type is ",
          localItemDiscountLoyalty.type,
          " and its value is ",
          localItemDiscountLoyalty.value
        );

        itemDiscountValue += localItemDiscountLoyalty.value;

        // if (localItemDiscountLoyalty.type === "percentage") {
        //   itemDiscountValue += parseFloat(
        //     ((itemUnitPrice * quantity * localItemDiscountLoyalty.value) / 100).toFixed(2)
        //   );
        // } else {
        //   itemDiscountValue += localItemDiscountLoyalty.value;
        // }
      }

      Vue.$log.debug(
        "After validating the line's discount and the loyalty discount, the VALUE of the discount is ",
        itemDiscountValue
      );

      if (cartProduct) {
        cartProduct.item_unit_price = itemUnitPrice;
        cartProduct.discount = localItemDiscount;
        cartProduct.discount_loyalty = localItemDiscountLoyalty;
        cartProduct.quantity = quantity;
        //The Discount's value can never be higher than the Line's Total Price
        cartProduct.discount_value_total = Math.min(itemDiscountValue, itemTotalPriceWithoutDiscount);
      } else {
        let cartItemOrder = 1;
        if (state.activeCart.items.length > 0) {
          if (state.activeCart.items[state.activeCart.items.length - 1].order) {
            cartItemOrder = state.activeCart.items[state.activeCart.items.length - 1].order + 1;
          }
        }
        state.activeCart.items.push({
          // Item newly added to cart
          item_id: itemId,
          item_code: itemCode,
          item_name: itemName,
          item_unit_price: itemUnitPrice,
          item_image: null,
          item_family: null,
          quantity: quantity,
          discount: localItemDiscount,
          discount_loyalty: localItemDiscountLoyalty,
          //The Discount's value can never be higher than the Line's Total Price
          discount_value_total: Math.min(itemDiscountValue, itemTotalPriceWithoutDiscount),
          menu_items: itemMenu,
          components: itemComponent,
          order: cartItemOrder,
        });
      }

      // Setting the new state of the cart to the Local Storage
      localStorage.setItem("cart.activeCart", JSON.stringify(state.activeCart));
    },
    editMenuInCart: (state, { itemId, itemMenu, itemOrder }) => {
      // Find the item in the cart list
      let cartProduct = state.activeCart.items.find(
        (cartItem) => cartItem.item_id === itemId && cartItem.order === itemOrder
      );

      if (cartProduct) {
        cartProduct.menu_items = itemMenu;
      }

      // Setting the new state of the cart to the Local Storage
      localStorage.setItem("cart.activeCart", JSON.stringify(state.activeCart));
    },
    /**
     * Setting the store with the cart's definition which is stored on the Local Storage
     * @param state
     * @param items: an array of Items. Each Item's definition includes: item_id, item_code, item_name,
     *  item_unit_price, item_image and quantity
     */
    setShopCart: (state, items) => {
      if (localStorage.getItem("cart.activeCart")) {
        state.activeCart.items = items;

        // Setting the new state of the cart to the Local Storage
        localStorage.setItem("cart.activeCart", JSON.stringify(state.activeCart));
      }
    },
    setCartEmpty: (state) => {
      let activeCartZone = state.activeCart["zone"];

      if (localStorage.getItem("cart.activeCart")) {
        state.activeCart = {
          items: [],
          type: "sale",
          taxNumber: null,
          refDocument: null,
          loyaltyCardId: null,
          vatExempt: false,
          zone: activeCartZone,
          hash: uuidv4(),
        };

        // Setting the new state of the cart to the Local Storage
        localStorage.setItem("cart.activeCart", JSON.stringify(state.activeCart));
      }
      state.checkoutCartInProgress = false;
      state.checkoutSubmitAvailable = false;
    },
    setCartType: (state, type) => {
      state.activeCart.type = type;

      // Setting the new state of the cart to the Local Storage
      localStorage.setItem("cart.activeCart", JSON.stringify(state.activeCart));
    },
    setCartTaxNumber: (state, taxNumber) => {
      state.activeCart.taxNumber = taxNumber;

      // Setting the new state of the cart to the Local Storage
      localStorage.setItem("cart.activeCart", JSON.stringify(state.activeCart));
    },
    setCartRefDocument: (state, refDocument) => {
      state.activeCart.refDocument = refDocument;

      // Setting the new state of the cart to the Local Storage
      localStorage.setItem("cart.activeCart", JSON.stringify(state.activeCart));
    },
    setCartLoyaltyCardId: (state, loyaltyCardId) => {
      state.activeCart.loyaltyCardId = loyaltyCardId;

      // Setting the new state of the cart to the Local Storage
      localStorage.setItem("cart.activeCart", JSON.stringify(state.activeCart));
    },
    setCartVatExempt: (state, value) => {
      state.activeCart.vatExempt = value;

      // Setting the new state of the cart to the Local Storage
      localStorage.setItem("cart.activeCart", JSON.stringify(state.activeCart));
    },
    /**
     * Setting the Sale's Zone chosen to the Active Cart.
     * This includes defining both the Zone's "Code" and "Description" and its Sales Price Range
     * (the object "zone" of the Active Cart).
     *
     * Important note: changing the Sale's Zone when the Cart already has Items, might introduce a problem:
     *  the prices are invalid for the new Sale Price Range linked to the Sale's Zone.
     * To avoid this problem, the Cart is set to Empty, prior changing the Zone.
     * @param state
     * @param zone
     */
    setCartZone: (state, zone) => {
      //@modified ana.castro 2022.12.05 SAFO-47
      if ((state.activeCart.zone && state.activeCart.zone.code !== zone) || !state.activeCart.zone) {
        if (state.activeCart.items.length > 0) {
          //Changing the Sales Price Range when the Cart already has Items, might introduce a problem:
          // the prices are invalid for the new Sale Price Range.
          //To avoid this problem, the Cart is set to Empty, prior changing the Sales price Range.
          store.dispatch("cart/setCartEmpty");
        }

        if (state.activeCart.zone) {
          state.activeCart.zone.code = zone;
        } else {
          state.activeCart["zone"] = { code: zone, verbose: null, salespricerange: 1, selfexpenditure: 0 };
        }

        store.dispatch("cart/fetchCartZoneDetails");

        // Setting the new state of the cart to the Local Storage
        localStorage.setItem("cart.activeCart", JSON.stringify(state.activeCart));
      }
    },
    /**
     * Setting the Sale's Zone description chosen to the Active Cart.
     *
     * Important note: changing the Sale's Zone when the Cart already has Items, might introduce a problem:
     *  the prices are invalid for the new Sale Price Range linked to the Sale's Zone.
     * To avoid this problem, the Cart is set to Empty, prior changing the Zone.
     * @param state
     * @param zoneVerbose
     */
    setCartZoneVerbose: (state, zoneVerbose) => {
      state.activeCart.zone.verbose = zoneVerbose;

      // Setting the new state of the cart to the Local Storage
      localStorage.setItem("cart.activeCart", JSON.stringify(state.activeCart));
    },
    /**
     * Setting the Sale's Zone Sales Price Range chosen to the Active Cart.
     *
     * Important note: changing the Sale's Zone when the Cart already has Items, might introduce a problem:
     *  the prices are invalid for the new Sale Price Range linked to the Sale's Zone.
     * To avoid this problem, the Cart is set to Empty, prior changing the Zone.
     * @param state
     * @param zoneSalesPriceRange
     */
    setCartZoneSalesPriceRange: (state, zoneSalesPriceRange) => {
      //@modified ana.castro 2022.12.05 SAFO-47
      state.activeCart.zone.salespricerange = zoneSalesPriceRange;

      // Setting the new state of the cart to the Local Storage
      localStorage.setItem("cart.activeCart", JSON.stringify(state.activeCart));
    },
    /**
     * Setting the Sale's Zone Self Expenditure attribute chosen to the Active Cart.
     *
     * Important note: changing the Sale's Zone when the Cart already has Items, might introduce a problem:
     *  the prices are invalid for the new Sale Price Range linked to the Sale's Zone.
     * To avoid this problem, the Cart is set to Empty, prior changing the Zone.
     * @param state
     * @param zoneSelfExpenditure
     */
    setCartZoneSelfExpenditure: (state, zoneSelfExpenditure) => {
      //@modified ana.castro 2022.12.05 SAFO-47
      state.activeCart.zone.selfexpenditure = zoneSelfExpenditure;

      // Setting the new state of the cart to the Local Storage
      localStorage.setItem("cart.activeCart", JSON.stringify(state.activeCart));
    },
    /**
     * The Saved Carts are stored with 5 elements:
     * 1 - the Items
     * 2 - the Date when the Cart is being saved
     * 3 - the Total Value of the Items included on the Cart
     * 4 - the Customer's identifier - the Mobile Phone (when the Customer exists, which normally is linked to the definition of a Budget)
     * 5 - an extra identifier which now is being used to store the Sale's Document identifier (which normally is linked to the definition of a Budget)
     * @param state
     * @param totalAmount
     * @param customer
     * @param extraIdentifier
     * @param selectedSalesman
     */
    saveActiveCart: (state, { totalAmount, customer, extraIdentifier, selectedSalesman }) => {
      // Working on the Current Date value to assign it to the Saved Cart on the format yyyy-mm-dd HH:MM:SS
      let currentdate = new Date();
      let month = "" + (currentdate.getMonth() + 1);
      let day = "" + currentdate.getDate();
      let hours = "" + currentdate.getHours();
      let minutes = "" + currentdate.getMinutes();
      let seconds = "" + currentdate.getSeconds();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      if (hours.length < 2) hours = "0" + hours;
      if (minutes.length < 2) minutes = "0" + minutes;
      if (seconds.length < 2) seconds = "0" + seconds;

      let datetime = currentdate.getFullYear() + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;

      //The unshift is used to add the cart to the beginning of the SavedCarts list. This way,
      // the most recent Saved Carts are displayed first
      state.savedCarts.unshift({
        items: state.activeCart.items,
        dateHour: datetime,
        totalAmount: totalAmount,
        customer: customer,
        extraIdentifier: extraIdentifier,
        vatExempt: state.activeCart.vatExempt,
        zone: state.activeCart.zone,
        salesmanName: selectedSalesman,
      });
      // Setting the new state of the cart to the Local Storage
      localStorage.setItem("cart.savedCarts", JSON.stringify(state.savedCarts));

      state.checkoutCartInProgress = false;
      state.checkoutSubmitAvailable = false;
    },

    activateSavedCart: (state, index) => {
      let savedCart = state.savedCarts[index];
      if (localStorage.getItem("cart.activeCart")) {
        state.activeCart.items = savedCart.items;
        state.activeCart.vatExempt = savedCart.vatExempt;
        state.activeCart.zone = savedCart.zone
          ? savedCart.zone
          : { code: 0, verbose: null, salespricerange: 1, selfexpenditure: 0 };
        state.activeCart.taxNumber = savedCart.customer ? savedCart.customer.taxNumber : null;
        state.activeCart.loyaltyCardId = savedCart.customer ? savedCart.customer.loyaltyCardId : null;
        state.activeCart.customerName = savedCart.customer ? savedCart.customer.name : null;
        state.activeCart.hash = uuidv4();

        localStorage.setItem("cart.activeCart", JSON.stringify(state.activeCart));
      }

      state.savedCarts.splice(index, 1);

      // Setting the new state of the cart to the Local Storage
      localStorage.setItem("cart.savedCarts", JSON.stringify(state.savedCarts));
    },
    deleteActiveCart: (state, index) => {
      state.savedCarts.splice(index, 1);

      localStorage.setItem("cart.savedCarts", JSON.stringify(state.savedCarts));
    },
    setCheckoutCartStatus: (state, status) => {
      state.checkoutCartInProgress = status === "inProgress";
      if (!state.checkoutCartInProgress) {
        state.checkoutSubmitAvailable = false;
      }
    },
    setCheckoutCartSubmitStatus: (state, status) => {
      state.checkoutSubmitAvailable = status === "available";
    },
    setLoyaltyCampaigns: (state, loyaltyCampaigns) => {
      state.appliedLoyaltyCampaigns = loyaltyCampaigns;
    },
  },
  actions: {
    /**
     * This action allows adding an Item to the Active Cart.
     * 1 - it is not possible to add Item's to Active Cart's of type "return"
     * 2 - the given Item (itemId) first is searched on the database of known Items, to collect all its data
     * 3 - the given Item (itemId) can be a Variant of a Main Item(parentItemId); in this cases the search on the database of known Items is performed differently
     * TODO atualizar comentário com referência ao menuChoices
     * @param commit
     * @param dispatch
     * @param state
     * @param itemId
     * @param parentItemId
     * @param menuChoices
     * @param extraInfo
     * @param quantity
     */
    addToCart: ({ commit, dispatch, state }, { itemId, parentItemId, menuChoices, extraInfo, quantity }) => {
      //In a Return Document, it is not possible to add Items to the Cart. The Items are restricted
      // to the Items included on the Return Document.
      if (state.activeCart.type === "return" || state.activeCart.type === "returnTotal") {
        dispatch("alert/error", this.$vuetify.lang.t("$vuetify.errorItemToReturn"), { root: true });
      } else {
        let item = null;
        if (!parentItemId) {
          store.dispatch("items/get", itemId).then((result) => {
            item = result[0];
            if (item) {
              if (item.data.item_type && item.data.item_type === 5) {
                item["extra_info"] = extraInfo;
              }

              if (item.data.type === 2 || item.data.type === 7) {
                let itemUnitPrice = store.getters["cart/itemUnitPrice"](item);

                let component = {
                  id: item.id,
                  code: item.code,
                  description: item.description,
                  item_unit_price: itemUnitPrice,
                };
                //Obter último artigo adicionado ao Cart
                //Se não houver artigo, apresentar sms de erro
                let lastAddedItem = state.activeCart.items[state.activeCart.items.length - 1];
                if (lastAddedItem) {
                  if (lastAddedItem.components) {
                    lastAddedItem["components"].push(component);
                  } else {
                    lastAddedItem["components"] = [component];
                  }
                  localStorage.setItem("cart.activeCart", JSON.stringify(state.activeCart));

                  // Display new item info plus cart total amount
                  dispatch("setClientsDisplay", {
                    item_description: item.description,
                    item_price: item.item_unit_price,
                  });

                  dispatch(
                    "alert/success",
                    "Artigo" + "  " + item.code + " - " + item.description + " " + "adicionado à Venda.",
                    { root: true }
                  );
                } else {
                  dispatch("alert/error", this.$vuetify.lang.t("$vuetify.errorItemInfoExtra"), {
                    root: true,
                  });
                }
              } else {
                if (menuChoices) {
                  item["menu_items"] = menuChoices;
                }
                commit("addToCart", { item, quantity });

                // Display new item info plus cart total amount
                dispatch("setClientsDisplay", { item: item });

                dispatch(
                  "alert/success",
                  "Artigo" + "  " + item.code + " - " + item.description + " " + "adicionado à Venda.",
                  { root: true }
                );
              }
            } else {
              dispatch("alert/error", vuetify.framework.lang.t("$vuetify.itemNotFoundMessage"), { root: true });
            }
          });
        } else {
          store.dispatch("items/get", parentItemId).then((result) => {
            let parentItem = result[0];
            item = parentItem.data.variantsDetails.filter((item) => item.id === itemId);

            if (item.length > 0) {
              commit("addToCart", { item: item[0], quantity });

              // Display new item info plus cart total amount
              dispatch("setClientsDisplay", { item: item[0] });

              dispatch(
                "alert/success",
                "Artigo" + "  " + item[0].code + " - " + item[0].description + " " + "adicionado à Venda.",
                { root: true }
              );
            } else {
              dispatch("alert/error", vuetify.framework.lang.t("$vuetify.itemNotFoundMessage"), { root: true });
            }
          });
        }
      }
    },
    /**
     * This action show information in clients display
     * */
    /* eslint-disable */
    async setClientsDisplay({ getters }, msgObj) {
      /* Desligado temporariamente */
      return;
      const url = "http://127.0.0.1:9090/senddata/";
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      let totalAmount = getters.cartTotalAmount;
      let item_description = "";
      let item_unit_price = "";
      if (msgObj && msgObj.item !== undefined) {
        item_description = msgObj.item.description;
        item_unit_price = store.getters["cart/itemUnitPrice"](msgObj.item).toFixed(2);
      }
      if (msgObj && msgObj.item_description !== undefined) {
        item_description = msgObj.item_description;
      }
      if (msgObj && msgObj.item_unit_price !== undefined) {
        item_unit_price = msgObj.item_unit_price;
      }

      let msg =
        item_description.slice(0, 14).padEnd(14) +
        item_unit_price.slice(0, 6).padStart(6) +
        "Total:".slice(0, 11).padStart(11) +
        totalAmount.slice(0, 9).padStart(9);

      const formData = new FormData();
      formData.append("DEVICE", 3);
      formData.append("file", msg);

      Vue.axios
        .post(url, formData, config)
        .then((response) => Vue.$log.debug("Response:", response.message))
        .catch((error) => {
          Vue.$log.warn(error.message);
        });
    },
    /* eslint-enable */
    removeFromCart: ({ commit, dispatch }, itemId) => {
      commit("removeFromCart", itemId);
      // Display Cart Total Amount
      dispatch("setClientsDisplay", {});
    },
    deleteFromCart: ({ commit, dispatch }, { itemId, itemOrder }) => {
      commit("deleteFromCart", { itemId, itemOrder });
      // Display Cart Total Amount
      dispatch("setClientsDisplay", {});
    },
    editElementInCart: (
      { commit, dispatch },
      {
        itemId,
        itemCode,
        itemUnitPrice,
        itemDiscount,
        itemDiscountType,
        quantity,
        itemName,
        itemMenu,
        itemComponent,
        itemOrder,
      }
    ) => {
      commit("editElementInCart", {
        itemId,
        itemCode,
        itemUnitPrice,
        itemDiscount,
        itemDiscountType,
        quantity,
        itemName,
        itemMenu,
        itemComponent,
        itemOrder,
      });
      dispatch("setClientsDisplay", {});
    },
    editMenuInCart: ({ commit, dispatch }, { itemId, itemMenu, itemOrder }) => {
      commit("editMenuInCart", {
        itemId,
        itemMenu,
        itemOrder,
      });
      // Display Cart Total Amount
      dispatch("setClientsDisplay", {});
    },
    setShopCart: ({ commit }, shopCart) => {
      commit("setShopCart", shopCart);
    },
    setCartEmpty: ({ commit }) => {
      commit("setCartEmpty");
    },
    setCartType: ({ commit }, type) => {
      commit("setCartType", type);
    },
    setCartTaxNumber: ({ commit }, taxNumber) => {
      commit("setCartTaxNumber", taxNumber);
    },
    setCartRefDocument: ({ commit }, refDocument) => {
      commit("setCartRefDocument", refDocument);
    },
    setCartLoyaltyCardId: ({ commit }, loyaltyCardId) => {
      commit("setCartLoyaltyCardId", loyaltyCardId);
    },
    async setCartVatExempt({ commit, state }, value) {
      commit("setCartVatExempt", value);

      //Iterating through each Cart's Item and setting the correct Unit Price
      for (const element of state.activeCart.items) {
        let itemUnitPrice = null;

        // modified 2022.07.28 @daniel.silva
        // if unit price equals item price use saved values, if not, we calculate the price proportionally
        if (element.item_price === element.item_unit_price) {
          if (state.activeCart.vatExempt) {
            itemUnitPrice = element.item_net_price;
          } else {
            itemUnitPrice = element.item_price;
          }
        } else {
          if (state.activeCart.vatExempt) {
            itemUnitPrice = (element.item_unit_price * element.item_net_price) / element.item_price;
          } else {
            itemUnitPrice = (element.item_unit_price * element.item_price) / element.item_net_price;
          }
        }

        commit("editElementInCart", {
          itemId: element.item_id,
          itemUnitPrice: itemUnitPrice,
          itemDiscount: element.discount.value,
          itemDiscountType: element.discount.type,
          quantity: element.quantity,
          itemOrder: element.order,
        });
      }
    },
    //@modified ana.castro 2022.12.05 SAFO-47
    // setCartSalePriceRange: ({ commit }, salesPriceRange) => {
    //   commit("setCartSalePriceRange", salesPriceRange);
    // },

    setCartZone: ({ commit }, zone) => {
      commit("setCartZone", zone);
    },
    saveActiveCart: ({ commit, getters }, { customer, extraIdentifier, selectedSalesman }) => {
      //Getting the Actual Cart's Total Value, since this information is also stored on the Saved Carts
      let totalAmount = getters.cartTotalAmount;

      commit("saveActiveCart", { totalAmount, customer, extraIdentifier, selectedSalesman });
      commit("setCartEmpty");
    },
    activateSavedCart: ({ commit }, index) => {
      commit("activateSavedCart", index);
    },
    deleteSavedCart: ({ commit }, index) => {
      commit("deleteActiveCart", index);
    },
    /**
     * Important Rule: if the item already has a discount, the discount is ignored.
     * Example: if a discount was defined specifically for an Item's line and then a Global Discount is set, the
     * "individual" discount is ignored.
     * @param commit
     * @param getters
     * @param state
     * @param discountValue
     * @param discountType
     */
    setCartGlobalDiscount: ({ commit, dispatch, getters, state }, { discountValue, discountType }) => {
      let sumItemsDiscountValue = 0;
      let percentageDiscount = 0;
      let itemDiscountValue = 0;

      //The discount is applied to the original prices of the Items.
      //Therefore, it is necessary to get the Value of the Cart, ignoring the Discounts
      let totalAmount = getters.cartTotalAmountWithoutDiscount;

      //Converting the Discount Value to a Percentage
      if (discountType === "value") {
        percentageDiscount = (discountValue * 100) / totalAmount;
      } else {
        percentageDiscount = discountValue;
      }

      //Iterating through each Cart's Item and spreading the Discount
      for (const element of state.activeCart.items) {
        let itemTotalPrice = element.quantity * element.item_unit_price;
        if (discountType === "value") {
          itemDiscountValue = (itemTotalPrice * percentageDiscount) / 100;
        } else {
          itemDiscountValue = parseFloat(percentageDiscount);
        }
        itemDiscountValue = parseFloat(itemDiscountValue.toFixed(2));

        if (discountType === "value") {
          sumItemsDiscountValue += itemDiscountValue;
          if (sumItemsDiscountValue > parseFloat(discountValue)) {
            itemDiscountValue -= sumItemsDiscountValue - parseFloat(discountValue);
          }
        }

        commit("editElementInCart", {
          itemId: element.item_id,
          itemUnitPrice: element.item_unit_price,
          itemDiscount: itemDiscountValue,
          itemDiscountType: discountType,
          quantity: element.quantity,
          itemOrder: element.order,
        });

        // Display Cart Total Amount
        dispatch("setClientsDisplay", {});
      }
    },
    removeCartDiscountLoyalty: ({ commit, dispatch, state }) => {
      state.appliedLoyaltyCampaigns = [];

      //Iterating through each Cart's Item and removing the discount
      for (const element of state.activeCart.items) {
        if (element.discount_loyalty && element.discount_loyalty.value > 0) {
          commit("editElementInCart", {
            itemId: element.item_id,
            itemUnitPrice: element.item_unit_price,
            itemDiscountLoyalty: 0,
            itemDiscountLoyaltyType: null,
            quantity: element.quantity,
            itemOrder: element.order,
          });
        }
      }
      // Display Cart Total Amount
      dispatch("setClientsDisplay", {});
    },
    setCheckoutCartStatus: ({ commit, dispatch }, status) => {
      commit("setCheckoutCartStatus", status);
      // Display Cart Total Amount
      dispatch("setClientsDisplay", {});
    },
    setCheckoutCartSubmitStatus: ({ commit }, status) => {
      commit("setCheckoutCartSubmitStatus", status);
    },
    setLoyaltyCampaigns: ({ commit }, loyaltyCampaigns) => {
      commit("setLoyaltyCampaigns", loyaltyCampaigns);
    },
    setCartLoyaltyCampaignDiscount: ({ commit, dispatch, getters, state }, { discountValue, discountType }) => {
      let sumItemsDiscountValue = 0;
      let percentageDiscount = 0;
      let itemDiscountValue = 0;
      let itemDiscountInValue = 0;

      //The discount is applied to the original prices of the Items.
      //Therefore, it is necessary to get the Value of the Cart, ignoring the Discounts
      // @modified ana.castro 2023.10.11
      //let totalAmount = getters.cartTotalAmountWithoutDiscount;
      let totalAmount = getters.cartTotalAmount;

      //Converting the Discount Value to a Percentage
      if (discountType === "value") {
        percentageDiscount = (discountValue * 100) / totalAmount;
      } else {
        percentageDiscount = discountValue;
      }
      //Iterating through each Cart's Item and spreading the Discount
      for (const element of state.activeCart.items) {
        let itemTotalPrice = element.quantity * element.item_unit_price;
        // @modified ana.castro 2023.10.11
        itemTotalPrice -= element.discount_value_total;

        if (discountType === "value") {
          itemDiscountValue = (itemTotalPrice * percentageDiscount) / 100;
        } else {
          itemDiscountValue = parseFloat(percentageDiscount);

          itemDiscountInValue = (element.item_unit_price * element.quantity * itemDiscountValue) / 100;
        }
        itemDiscountValue = parseFloat(itemDiscountValue.toFixed(2));

        if (discountType === "value") {
          sumItemsDiscountValue += itemDiscountValue;

          if (sumItemsDiscountValue > parseFloat(discountValue)) {
            itemDiscountValue -= sumItemsDiscountValue - parseFloat(discountValue);
          }
          itemDiscountInValue = itemDiscountValue;
        }

        if (discountType === "value") {
          itemDiscountValue = itemDiscountInValue;
        }
        commit("editElementInCart", {
          itemId: element.item_id,
          itemUnitPrice: element.item_unit_price,
          itemDiscountLoyalty: itemDiscountInValue,
          itemDiscountLoyaltyType: discountType,
          quantity: element.quantity,
          itemOrder: element.order,
        });
      }
      // Display Cart Total Amount
      dispatch("setClientsDisplay", {});
    },
    /**
     *
     * @param commit
     * @param state
     * @returns {Promise<null>}
     */
    async fetchCartZoneDetails({ commit, state }) {
      let zoneVerbose = null;
      let zoneSalesPriceRange = null;
      let zoneSelfExpenditure = 0;
      if (state.activeCart.zone.code) {
        let result = await store.getters["stores/getZones"](state.activeCart.zone.code);

        if (result[0]) {
          zoneVerbose = result[0]["description"];
          zoneSalesPriceRange = result[0]["salesPriceRange"];
          zoneSelfExpenditure = result[0]["data"]["SelfExpenditure"];
        } else {
          zoneVerbose = null;
          zoneSalesPriceRange = 1;
          zoneSelfExpenditure = 0;
        }
      } else {
        zoneVerbose = null;
        zoneSalesPriceRange = 1;
        zoneSelfExpenditure = 0;
      }
      commit("setCartZoneVerbose", zoneVerbose);
      commit("setCartZoneSalesPriceRange", zoneSalesPriceRange);
      commit("setCartZoneSelfExpenditure", zoneSelfExpenditure);
    },
  },
  getters: {
    cartSize: (state) => {
      if (!state.activeCart.items) {
        return 0;
      } else {
        return state.activeCart.items.length;
      }
    },
    cartTotalAmount: (state) => {
      let totalAmount = 0;
      for (const element of state.activeCart.items) {
        //@modified ana.castro 2021.04.20
        // (element.discount || 0) the OR was added because when this version will be installed, it is possible that
        // there is information stored on the Local Storage, which does not include the "discount"
        totalAmount += element.item_unit_price * element.quantity - (element.discount_value_total || 0);
        if (element.menu_items) {
          //console.info(1, element.menu_items);
          //console.info(2, Object.keys(element.menu_items));
          for (let i = 0; i < Object.keys(element.menu_items).length; i++) {
            for (let menuLevelItem of element.menu_items[i].items) {
              totalAmount += menuLevelItem.item_unit_price * element.quantity;
            }
          }
        }
      }

      return displayWithCents(round2Decimals(totalAmount));
    },
    cartTotalAmountWithoutDiscount: (state) => {
      let totalAmount = 0;
      for (const element of state.activeCart.items) {
        totalAmount += element.item_unit_price * element.quantity;
      }

      return displayWithCents(round2Decimals(totalAmount));
    },
    cartItems: (state) => {
      if (!state.activeCart.items) {
        return [];
      } else {
        return state.activeCart.items;
      }
    },
    cartType: (state) => {
      return state.activeCart.type;
    },
    cartRefDocument: (state) => {
      return state.activeCart.refDocument;
    },
    cartSalesPricesRange: (state) => {
      return state.activeCart.zone.salespricerange;
    },
    cartZone: (state) => {
      return state.activeCart.zone;
    },
    cartZoneVerbose: (state) => {
      return state.activeCart.zone.verbose;
    },
    cartTaxNumber: (state) => {
      return state.activeCart.taxNumber;
    },
    cartLoyaltyCardId: (state) => {
      return state.activeCart.loyaltyCardId;
    },
    cartCustomerName: (state) => {
      return state.activeCart.cartCustomerName;
    },
    cartVatExempt: (state) => {
      return state.activeCart.vatExempt;
    },
    savedShopCarts: (state) => {
      return state.savedCarts;
    },
    cartCheckoutInProgress: (state) => {
      return state.checkoutCartInProgress;
    },
    cartCheckoutSubmitAvailable: (state) => {
      return state.checkoutSubmitAvailable;
    },
    itemUnitPrice: (state) => (item) => {
      let itemUnitPrice = null;
      //@modified ana.castro 2022.12.05 SAFO-47
      let salespricerange = state.activeCart.zone.salespricerange;

      if (state.activeCart.vatExempt) {
        itemUnitPrice = store.getters["cart/itemNetUnitPrice"](item);
      } else {
        if (salespricerange === 1) {
          itemUnitPrice = item.data ? (item.data.price1 ? item.data.price1 : 0) : item.price1 ? item.price1 : 0;
        } else if (salespricerange === 2) {
          itemUnitPrice = item.data ? (item.data.price2 ? item.data.price2 : 0) : item.price2 ? item.price2 : 0;
        } else if (salespricerange === 3) {
          itemUnitPrice = item.data ? (item.data.price3 ? item.data.price3 : 0) : item.price3 ? item.price3 : 0;
        } else if (salespricerange === 4) {
          itemUnitPrice = item.data ? (item.data.price4 ? item.data.price4 : 0) : item.price4 ? item.price4 : 0;
        } else if (salespricerange === 5) {
          itemUnitPrice = item.data ? (item.data.price5 ? item.data.price5 : 0) : item.price5 ? item.price5 : 0;
        }
      }
      return itemUnitPrice;
    },
    itemNetUnitPrice: (state) => (item) => {
      let itemNetPrice = null;
      //@modified ana.castro 2022.12.05 SAFO-47
      let salespricerange = state.activeCart.zone.salespricerange;

      if (salespricerange === 1) {
        itemNetPrice = item.data
          ? item.data.netPrice1
            ? item.data.netPrice1
            : 0
          : item.netPrice1
          ? item.netPrice1
          : 0;
      } else if (salespricerange === 2) {
        itemNetPrice = item.data
          ? item.data.netPrice2
            ? item.data.netPrice2
            : 0
          : item.netPrice2
          ? item.netPrice2
          : 0;
      } else if (salespricerange === 3) {
        itemNetPrice = item.data
          ? item.data.netPrice3
            ? item.data.netPrice3
            : 0
          : item.netPrice3
          ? item.netPrice3
          : 0;
      } else if (salespricerange === 4) {
        itemNetPrice = item.data
          ? item.data.netPrice4
            ? item.data.netPrice4
            : 0
          : item.netPrice4
          ? item.netPrice4
          : 0;
      } else if (salespricerange === 5) {
        itemNetPrice = item.data
          ? item.data.netPrice5
            ? item.data.netPrice5
            : 0
          : item.netPrice5
          ? item.netPrice5
          : 0;
      }
      return itemNetPrice;
    },
  },
};
