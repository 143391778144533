import { getApiAuthToken, getApiRefreshAuthToken } from "@/_helpers";
import Vue from "vue";
import router from "../router";

export const userService = {
  login,
  logout,
  refreshToken,
  /*get,
      setNewProfile,
      setProfile,
      reviewPolicyTerms,
      verifyUser,
      getNewPassword,
      setNewPassword,
      loginSocialProvider,*/
};

/**
 * Implementing the User's login process in which an username and a password are used.
 * The backend url rest-auth/obtain_token/ is called and the request looks like this:
 * {password: "xxxxxxxxxxx"
    recaptcha_token: "03AOLTBLTlaqs...........wjH-OraLerLljHC"
    username: "351-9xxxxxxxx"}
 *
 * @param username
 * @param password
 * @param token
 * @returns {Promise<unknown>}
 */
function login(username, password) {
  const requestOptions = {
    method: "POST",
    url: getApiAuthToken(),
    headers: { "Content-Type": "application/json" },
    data: {
      username: username,
      password: password,
      store: router.currentRoute.params.store,
    },
  };

  //Inspirado em https://blog.pusher.com/promises-async-await/
  let p_obtainApiToken = new Promise((resolve, reject) => {
    Vue.axios(requestOptions).then(
      (result) => {
        let user = result.data;
        resolve(user);
      },
      (error) => {
        Vue.$log.warn(error);
        logout();
        reject(error);
      }
    );
  });

  return p_obtainApiToken;
}

/**
 * Implementing the User's login process using a Social Provider.
 * The backend url postoauth/ is called and the request looks like this:
 * {code: "EAAHOu0BPy4ABADZAXZCpdMoV...............hcQZDZD"
    provider: "FB"}
 *
 * @param provider
 * @param code
 * @returns {Promise<unknown>}
 */

/*
function loginSocialProvider(provider, code) {
  const requestOptions = {
    method: "POST",
    url: getApiUrl("postoauth/"),
    headers: { "Content-Type": "application/json" },
    data: { provider: provider, code: code },
  };

  //Inspirado em https://blog.pusher.com/promises-async-await/
  let p_obtainApiToken = new Promise((resolve, reject) => {
    Vue.axios(requestOptions).then(
      (result) => {
        Vue.$log.debug("API POST URL:", getApiUrl("postoauth"), provider, code);
        Vue.$log.debug("API POST CALL:", result.data);
        let user = result.data;
        resolve(user);
      },
      (error) => {
        Vue.$log.warn(error);
        logout();
        reject(error);
      }
    );
  });

  return p_obtainApiToken;
}
*/

/**
 * Implementing the Refresh of the JWT Token used on the Authorization header of every request.
 * The backend url rest-auth/refresh_token/ is called and the JWT Token included on the Authorization header
 * it's the Refresh Token and not the Access Token. The request looks like this:
 * {refresh: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIU............AGxRarcKFfsKbt-c2Wve7E"}
 *
 * @returns {Promise<unknown>}
 */
function refreshToken() {
  let user = JSON.parse(localStorage.getItem("user"));

  let requestOptions;

  if (user) {
    requestOptions = {
      method: "POST",
      url: getApiRefreshAuthToken(),
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + user.refresh,
      },
      data: { refresh: user.refresh },
    };
  }

  return Vue.axios(requestOptions).then(
    (result) => {
      let token = result.data;
      let user = JSON.parse(localStorage.getItem("user"));

      if (token.access) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        user.access = token.access;
        localStorage.setItem("user", JSON.stringify(user));
      }

      Vue.$log.debug("REFRESH TOKEN Sucesso | ", user, " New Token: ", user.access);

      return user.access;
    },
    (error) => {
      Vue.$log.debug("REFRESH TOKEN Insucesso | Logout | ", error);
      logout();
    }
  );
}

/**
 * Remove user from local storage to log user out
 */
function logout() {
  localStorage.removeItem("user");
  localStorage.removeItem("login_provider");
}

/*
function get() {
  // return apiFetch("getcustomer/").then(data => {
  //     return data
  // });
  return apiFetch("getcustomer/").then((data) => {
    let user = JSON.parse(localStorage.getItem("user"));
    if (data.gdpr_agreement === 0) {
      user.mandatoryValidateProfile = 1;
    }

    localStorage.setItem("user", JSON.stringify(user));

    return data;
  });
}

function setNewProfile(attributes) {
  return apiPost("setnewcustomer/", attributes);
}

function setProfile(attributes) {
  return apiPost("setcustomer/", attributes);
}

function reviewPolicyTerms(attributes) {
  return apiPost("reviewpolicyterms/", attributes);
}

function verifyUser(attributes) {
  return apiPost("verifyuser/", attributes);
}

function getNewPassword(attributes) {
  return apiPost("getcustomernewpassword/", attributes);
}

function setNewPassword(attributes) {
  return apiPost("setcustomerpassword/", attributes);
}*/
