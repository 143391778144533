import router from "@/router";
import Dexie from "dexie";

/**
 *
 * @returns {Dexie}
 */
export function getLocalDataBase() {
  if (router.currentRoute.params.tenant && router.currentRoute.params.store) {
    let databasename = "EAPOS_" + router.currentRoute.params.tenant + "_" + router.currentRoute.params.store;

    // Declare db instance
    var db = new Dexie(databasename);
    // Define Database Schema
    db.version(12).stores({
      entities: "entity",
      // families:
      //   "id, familyLevel, parentId, status, subFamilies, synccounter, data",
      families: "id, code, [familyLevel+status], [parentCode+status], subFamilies, synccounter, data",
      salesmen: "id, name, status, synccounter, sessionStatus, sessionDate, data",
      paymentMethods: "id, status, synccounter, data",
      // items: "id, code, barcode, description, family, status, synccounter, data",
      items: "id, status, code, barcode, mainDescription, description, [family+status], synccounter, data",
      cashDrawers: "id, tillId, description, isOpen, [isOpen+status], status, default, synccounter, data",
      itemsSearchTerms: "id, status, mainDescription, description, code, barcode, parentItem",
      countries: "id, status, name, ISOCountryId, synccounter, data",
      // salesPricesRanges: "code, description, status",
      //@modified ana.castro 2022.12.05 SAFO-47
      zones: "code, description, status, salesPriceRange, data",
      permissionsGroup: "id, description, status, synccounter, activePermissions",
      extensionFields: "id, code, model, name, required, type, data",
      //@modified ana.castro 2023.08.08 SAFO-75
      //actionType: apiPost, apiFetch, Vue.axios, loadingLayer
      //data: a dictionary with details
      posDataLog: "++id, date, actionType, value, data",
      docTypes: "id, code, nature, description, synccounter, data",
    });

    return db;
  } else {
    setTimeout(function () {
      getLocalDataBase();
    }, 3.0 * 1000); //retry after 3 seconds
  }
}

export function updateDatabase(ref_object, force) {
  let autoSync = 1;
  let forceUpdate = force ?? 0;
  if (router.currentRoute.params.tenant && router.currentRoute.params.store) {
    ref_object.$store.dispatch("families/setConfiguration", {
      forceUpdate,
      autoSync,
    });
    ref_object.$store.dispatch("items/setConfiguration", {
      forceUpdate,
      autoSync,
    });
    ref_object.$store.dispatch("paymentTypes/setConfiguration", {
      forceUpdate,
      autoSync,
    });
    ref_object.$store.dispatch("salesmen/setConfiguration", {
      forceUpdate,
      autoSync,
    });
    ref_object.$store.dispatch("salesmen/setConfigurationPermissionGroup", {
      forceUpdate,
      autoSync,
    });
    ref_object.$store.dispatch("cashDrawers/setConfiguration", {
      forceUpdate,
      autoSync,
    });
    ref_object.$store.dispatch("countries/setConfiguration", {
      forceUpdate,
      autoSync,
    });
    // ref_object.$store.dispatch("stores/setSalesPricesRangesConfiguration", {
    //   forceUpdate,
    //   autoSync,
    // });
    ref_object.$store.dispatch("stores/setZonesConfiguration", {
      forceUpdate,
      autoSync,
    });
    ref_object.$store.dispatch("modelExtension/setConfiguration", {
      forceUpdate,
      autoSync,
    });
    ref_object.$store.dispatch("docTypes/setConfiguration", {
      forceUpdate,
      autoSync,
    });

    // setInterval(updateDatabase, 180000, ref_object);
  }
}
