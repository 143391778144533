import { apiFetch, getApiBaseStore, getApiParamsString } from "@/_helpers";

export const itemsService = {
  getAll,
  getStock,
};

async function getAll(synccounter) {
  let items = [];

  let result = [];
  let i = 1;
  let fetchPath = "pos/simple/parentitems/?store=" + getApiBaseStore();

  if (synccounter) {
    var params = {
      synccounter: synccounter,
    };

    fetchPath += "&" + getApiParamsString(params);
  }

  do {
    result = await apiFetch(fetchPath + "&page=" + i);
    items = items.concat(result);
    i += 1;
  } while (result.length > 0 && i < 2000);

  return items;
}

async function getStock(itemCode) {
  let itemsStock = [];

  let result = [];
  let i = 1;
  let fetchPath = "pos/stocks/?ItemId=" + itemCode;

  do {
    result = await apiFetch(fetchPath + "&page=" + i);
    itemsStock = itemsStock.concat(result.results);
    i += 1;
  } while (result.next && i < 2000);

  return itemsStock;
}
