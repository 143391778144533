import { apiFetch, getApiBaseStore, getApiParamsString } from "@/_helpers";

export const paymentTypesService = {
  getAll,
  validatePaymentMethodAuto,
};

async function getAll(synccounter) {
  let paymentTypes = [];

  let result = [];
  let i = 1;
  let fetchPath = "pos/paymenttypes/?store=" + getApiBaseStore();

  if (synccounter) {
    var params = {
      synccounter: synccounter,
    };

    fetchPath += "&" + getApiParamsString(params);
  }

  do {
    result = await apiFetch(fetchPath + "&page=" + i);
    paymentTypes = paymentTypes.concat(result.results);
    i += 1;
  } while (result.next && i < 2000);

  return paymentTypes;
}

function validatePaymentMethodAuto(attributes) {
  let str = Object.entries(attributes)
    .map(([key, val]) => `${key}=${val}`)
    .join("&");
  return apiFetch("pos/validatepaymentypeauto/?" + str + "&Store=" + getApiBaseStore());
}
