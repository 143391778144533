import {apiFetch, apiPost, buildLocalDatetime, getApiBaseStore, getApiParamsString, round2Decimals} from "@/_helpers";
import router from "@/router";
import { store } from "../store";

export const salesServices = {
  getAll,
  setSale,
  setCancelSale,
  getServerTime,
  getStockExit,
};

function getAll(params) {
  return apiFetch("internal/sales/?" + getApiParamsString(params));
}

function getServerTime() {
  return apiFetch("pos/servertime");
}

function getStockExit(params) {
  let fetchPath = "headoffice/exitmovements/full/?store=" + getApiBaseStore();
  fetchPath += "&stock_management=0";
  fetchPath += "&" + getApiParamsString(params);

  return apiFetch(fetchPath);
}

function setSale(attributes) {
  let submitData = {};

  let cartItems = attributes.items;

  let saleItems = [];
  let totalValue = 0;
  let totalDiscount = 0;
  for (const element of cartItems) {
    let saleItem = {};

    let taxIncluded = 0;

    let itemDiscount = 0;

    taxIncluded = round2Decimals(element.item_unit_price * element.quantity - (element.discount_value_total || 0));
    itemDiscount = round2Decimals(element.discount_value_total || 0);

    totalValue += taxIncluded;
    totalDiscount += itemDiscount;

    saleItem = {
      ItemId: element.item_code,
      Quantity: element.quantity,
      TaxIncludedPrice: element.item_unit_price,
      DiscountPercent: element.discount_type === "percentage" ? element.discount : null,
      TotalLineItemDiscountAmount: itemDiscount,
      TotalTaxIncludedAmount: taxIncluded,
      SalesmanId: attributes.salesman,
      ExtraInfo: element.item_extra_info,
    };

    saleItems.push(saleItem);

    if (element.menu_items && Object.keys(element.menu_items).length > 0) {
      for (let i = 0; i < Object.keys(element.menu_items).length; i++) {
        for (let menuLevelItem of element.menu_items[i].items) {
          taxIncluded = parseFloat(menuLevelItem.item_unit_price * element.quantity);
          totalValue += taxIncluded;

          saleItem = {
            ItemId: menuLevelItem.code,
            ParentItemId: element.item_code,
            Quantity: element.quantity,
            TaxIncludedPrice: menuLevelItem.item_unit_price,
            TotalTaxIncludedAmount: round2Decimals(menuLevelItem.item_unit_price * element.quantity),
            SalesmanId: attributes.salesman,
          };
          saleItems.push(saleItem);
        }
      }
    }
    if (element.components && element.components.length > 0) {
      for (let component of element.components) {
        taxIncluded = parseFloat(component.item_unit_price * element.quantity);
        totalValue += taxIncluded;

        saleItem = {
          ItemId: component.code,
          ParentItemId: element.item_code,
          Quantity: element.quantity,
          TaxIncludedPrice: component.item_unit_price,
          TotalTaxIncludedAmount: round2Decimals(component.item_unit_price * 1),
          SalesmanId: attributes.salesman,
        };
        saleItems.push(saleItem);
      }
    }
  }

  let paymentMethods = attributes.paymentMethods;
  let saleTenders = [];

  for (var j = 0; j < paymentMethods.length; j++) {
    if (paymentMethods[j]["method"] && paymentMethods[j].value) {
      let saleTender = {
        TenderId: paymentMethods[j]["method"],
        PaymentAmount: paymentMethods[j].value,
      };
      //@modified ana.castro 2021.09.14 SABO-2147
      if (paymentMethods[j]["voucherCode"]) {
        saleTender["VoucherTender"] = {
          VoucherTransDocument: paymentMethods[j]["voucherCode"].split(" ")[0],
          VoucherTransSerial: paymentMethods[j]["voucherCode"].split(" ")[1].split("/")[0],
          VoucherTransDocNumber: paymentMethods[j]["voucherCode"].split(" ")[1].split("/")[1],
        };
      }
      saleTenders.push(saleTender);
    }
  }

  submitData = {
    // LoyaltyCustomerId: attributes.customerId,
    LoyaltyCardId: attributes.loyaltyCardId,
    CustomerId: attributes.customerId,
    CustomerCompanyName: attributes.customerName,
    CustomerTaxId: attributes.taxNumber,
    TotalLineItemDiscountAmount: totalDiscount,
    DiscountPercent: parseFloat(0),
    TotalPaymentDiscountAmount: parseFloat(0),
    TotalTransactionAmount: round2Decimals(totalValue),
    TransDocument: attributes.documentType,
    TransNature: attributes.documentNature,
    TransDocumentDescription: attributes.documentDescription,
    NatureDescription: attributes.documentNatureDescription,
    TransUseInSales: attributes.documentUseInSales,
    TransStockManagement: attributes.transStockManagement,
    TransactionTaxIncluded: true,
    StoreId: router.currentRoute.params.store,
    SalesmanId: attributes.salesman,
    Comments: attributes.comments,
    OriginatingOn: attributes.refDocument,
    TillId: attributes.cashDrawer,
    LoyaltyProcessed: 0,
    //Possible Values 1, 2, 3, 4 or 5: PVP1, PVP2, ....
    SalesPriceRangeId: attributes.salesPricesRangeCode,
    //@modified ana.castro 2022.12.05 SAFO-47
    ZoneId: attributes.zone,

    SaleTransactionDetails: saleItems,

    TenderlineItems: saleTenders,

    RescuedLoyaltyCampaigns: attributes.rescuedLoyaltyCampaigns,

    ShipToAddressDetail: attributes.saleInvoiceData ? attributes.saleInvoiceData.shipToAddressDetail : null,
    ShipToAddressPostalCode: attributes.saleInvoiceData ? attributes.saleInvoiceData.shipToAddressPostalCode : null,
    ShipToAddressCity: attributes.saleInvoiceData ? attributes.saleInvoiceData.shipToAddressCity : null,
    ShipToAddressCountry:
      attributes.saleInvoiceData && attributes.saleInvoiceData.shipToAddressCountry
        ? attributes.saleInvoiceData.shipToAddressCountry.value
        : null,
    CartHash: attributes.hash,
  };

  if (attributes.vatExempt) {
    submitData["VatExempt"] = 1;
  }

  if (attributes.salesExtensionAttributes) {
    submitData["SalesExtensionAttributes"] = attributes.salesExtensionAttributes;
  }

  if (store.getters["configs/useLocalTime"]) {
    submitData["InvoiceDate"] = buildLocalDatetime();
  }

  return apiPost("internal/sales/", submitData);
}

function setCancelSale(attributes) {
  return apiPost("internal/sales/", attributes);
}
