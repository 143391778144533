import Vue from "vue";
import router from "@/router";
import { store } from "@/store";
import vuetify from "@/plugins/vuetify";

export function getApiBaseUrl() {
  let url;

  //TODO ver com o Daniel
  let tenant_id;
  if (router.currentRoute.name == null) {
    tenant_id = Vue.prototype.tenant_id;
  } else {
    tenant_id = router.currentRoute.params.tenant;
  }

  if (tenant_id === "dev") {
    url = "http://127.0.0.1:8000/";
    //url = "http://primefood.localhost:9000/";
  } else {
    url = "https://" + tenant_id + ".storesace.com/";
  }
  Vue.$log.debug("URL: " + url);
  return url;
}

export function getApiBaseStore() {
  let store_id;
  if (router.currentRoute.name == null) {
    store_id = Vue.prototype.store_id;
  } else {
    store_id = router.currentRoute.params.store;
  }
  Vue.$log.debug("STORE: " + store_id);
  return store_id;
}

export function getApiUrl(service_path) {
  if (!service_path) {
    service_path = "";
  }
  service_path = "dimas/api/" + service_path;

  return service_path;
}

export function getApiParamsString(params) {
  return Object.entries(params)
    .map(([key, val]) => `${key}=${val}`)
    .join("&");
}

export function getApiAuthToken() {
  return "dimas/api/rest-auth/obtain-token/";
}

export function getApiRefreshAuthToken() {
  return "dimas/api/rest-auth/refresh_token/";
}

export function apiFetch(service_path) {
  let requestOptions = {};

  requestOptions = {
    method: "GET",
    url: getApiUrl(service_path),
  };

  let actionType = "apiFetch-Before";
  let actionValue = getApiUrl(service_path);
  let actionData = {};
  let actionIdentifier = Math.floor(Math.random() * 1000000000);
  store.dispatch("dataLog/setData", {
    actionType,
    actionValue,
    actionData,
    actionIdentifier,
  });

  //Inspirado em https://blog.pusher.com/promises-async-await/
  let p_apiFetch = new Promise((resolve, reject) => {
    Vue.axios(requestOptions).then(
      (result) => {
        // Did our request got redirected to passwordchange?
        if (result.request.responseURL.includes("passwordchange")) {
          Vue.$log.debug("Password expired, logout...");
          store.dispatch("authentication/logout");
          window.location.href = "expired-password";
          reject();
        }

        actionData = { response: result.status };
        let actionType = "apiFetch-After";
        store.dispatch("dataLog/setData", {
          actionType,
          actionValue,
          actionData,
          actionIdentifier,
        });
        Vue.$log.debug("API URL:", getApiUrl(service_path));
        Vue.$log.debug("API CALL:", result.data);
        resolve(result.data);
      },
      (error) => {
        actionData = { response: error.message };
        let actionType = "apiFetch-Error";
        store.dispatch("dataLog/setData", {
          actionType,
          actionValue,
          actionData,
          actionIdentifier,
        });
        Vue.$log.warn(error);
        reject(error);
      }
    );
  });

  return p_apiFetch;
}

export function apiPost(service_path, data) {
  const requestOptions = {
    method: "POST",
    url: "dimas/api/" + service_path,
    data: data,
  };
  //TODO comentar para explicar
  let actionType = "apiPost";
  let actionValue = "dimas/api/" + service_path;
  let actionData = { data: data };
  let actionIdentifier = Math.floor(Math.random() * 1000000000);
  store.dispatch("dataLog/setData", {
    actionType,
    actionValue,
    actionData,
    actionIdentifier,
  });

  //Inspirado em https://blog.pusher.com/promises-async-await/
  let p_apiPost = new Promise((resolve, reject) => {
    Vue.axios(requestOptions).then(
      (result) => {
        actionData = { data: data, response: result.status };
        let actionType = "apiPost-After";
        store.dispatch("dataLog/setData", {
          actionType,
          actionValue,
          actionData,
          actionIdentifier,
        });
        Vue.$log.debug("API POST URL:", getApiUrl(service_path), data);
        Vue.$log.debug("API POST CALL:", result.data);
        resolve(result.data);
      },
      (error) => {
        actionData = { data: data, response: error.message };
        let actionType = "apiPost-Error";
        store.dispatch("dataLog/setData", {
          actionType,
          actionValue,
          actionData,
          actionIdentifier,
        });
        Vue.$log.warn(error);
        reject(error);
      }
    );
  });

  return p_apiPost;
}

/**
 * A function to deal with the errors obtained after a request. This performs three actions:
 * 1 - uses the Log to display the context where the error occurred
 * 2 - removes the Loading layer
 * 3 - triggers the alert component displaying the error message or a generic error message when the response has no information regarding the error
 * @param error
 * @param context
 * @param ref_object
 */
export function getErrorMessage(error, context, ref_object) {
  ref_object.$log.debug(context + " | Error");

  ref_object.$store.dispatch("loader/setLoading", false);

  if (error && error.response && error.response.data.status) {
    ref_object.$store.dispatch("alert/error", error.response.data.status);
  } else if (
    error &&
    error.response &&
    error.response.data.non_field_errors &&
    error.response.data.non_field_errors.length > 0
  ) {
    ref_object.$store.dispatch("alert/error", error.response.data.non_field_errors[0]);
  } else {
    ref_object.$store.dispatch("alert/error", vuetify.framework.lang.t("$vuetify.msgContentNotAvailable"));
  }
}

export function getUrlParams(url) {
  let params = {};
  url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    params[key] = value;
  });
  return params;
}

export function validateTaxNumberPT(nif_to_validate) {
  if (nif_to_validate && nif_to_validate.length === 9) {
    let added =
      nif_to_validate[7] * 2 +
      nif_to_validate[6] * 3 +
      nif_to_validate[5] * 4 +
      nif_to_validate[4] * 5 +
      nif_to_validate[3] * 6 +
      nif_to_validate[2] * 7 +
      nif_to_validate[1] * 8 +
      nif_to_validate[0] * 9;
    let mod = added % 11;
    let control;

    if (mod === 0 || mod === 1) {
      control = 0;
    } else {
      control = 11 - mod;
    }
    return parseInt(nif_to_validate[8]) === control;
  } else {
    return false;
  }
}

/**
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/EPSILON
 * @param number
 * @returns {number}
 */
export function round2Decimals(number) {
  return Math.round((number + Number.EPSILON) * 100) / 100;
}

export function displayWithCents(number) {
  return number.toFixed(2);
}

//TODO ana.castro ver com o Daniel esta implementação copiada de https://github.com/DVLP/localStorageDB/blob/master/localStorageDB.js
// e implementada às 3 pancadas
var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
if (!indexedDB) {
  console.error("indexDB not supported");
}
var dbInit = false;
var db, keyValue, databasename, request;

function getValue(key, callback) {
  if (!db) {
    setTimeout(function () {
      getValue(key, callback);
    }, 100);
    return;
  }
  db.transaction("str").objectStore("str").get(key).onsuccess = function (event) {
    var result = (event.target.result && event.target.result.v) || null;
    callback(result);
  };
}

// if using proxy mode comment this

export function ldb() {
  // init
  if (!dbInit) {
    dbInit = true;
    keyValue = {
      k: "",
      v: "",
    };
    databasename = "pointOfSale_" + router.currentRoute.params.tenant + "_" + router.currentRoute.params.store;
    request = indexedDB.open(databasename, 1);
    request.onsuccess = function () {
      db = this.result;
    };
    request.onerror = function (event) {
      console.error("indexedDB request error");
      console.log(event);
    };

    request.onupgradeneeded = function (event) {
      db = null;
      var store = event.target.result.createObjectStore("str", {
        keyPath: "k",
      });

      store.transaction.oncomplete = function (e) {
        db = e.target.db;
      };
    };
  }

  return {
    get: getValue,
    set: function (key, value) {
      // no callback for set needed because every next transaction will be anyway executed after this one
      keyValue.k = key;
      keyValue.v = value;
      db.transaction("str", "readwrite").objectStore("str").put(keyValue);
    },
  };
}

// Use only for apps that will only work on latest devices only

// export function ldb() {
//   return new Proxy(
//     {},
//     {
//       get: function (func, key) {
//         return key === "get"
//           ? getValue
//           : function (callback) {
//               return getValue(key, callback);
//             };
//       },
//       set: function (func, key, value) {
//         keyValue.k = key;
//         keyValue.v = value;
//         db.transaction("str", "readwrite").objectStore("str").put(keyValue);
//       },
//     }
//   );
// }

/**
 * Builds a local date string to be sent in the sale API
 * @returns {string}
 */
export function buildLocalDatetime() {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

/**
 * Validates if the doctype is valid for sale
 * @param doctype
 * @returns {boolean}
 */
export function isValidSaleDocType(doctype) {
  // FT - Fatura
  // FR - Fatura/Recibo
  // FS - Fatura Simplificada
  return ["FT", "FR", "FS"].includes(doctype);
}

/**
 * Validates if the doctype is valid for returns
 * @param doctype
 * @returns {boolean}
 */
export function isValidReturnDocType(doctype) {
  // NC - Nota de Crédito
  // ND - Nota de Débito
  return ["NC", "ND"].includes(doctype);
}
