import { apiFetch, apiPost, round2Decimals, getApiParamsString } from "@/_helpers";
import router from "../router";

export const customersServices = {
  get,
  getByCardId,
  getByCardTaxNumberMobilePhone,
  setCustomer,
  getCustomerAvailableCampaigns,
};

function get(identifier) {
  var params = {
    FederalTaxId: identifier,
    MobileTelephone1: identifier,
  };
  return apiFetch("pos/search/customers/?" + getApiParamsString(params));
}

function getByCardId(identifier) {
  var params = {
    CardId: identifier,
  };
  return apiFetch("pos/search/customers/?" + getApiParamsString(params));
}

function getByCardTaxNumberMobilePhone(identifier) {
  return new Promise((resolve, reject) => {
    customersServices.getByCardId(identifier).then(
      (result) => {
        if (result.count === 0) {
          customersServices.get(identifier).then(
            (result) => {
              resolve(result);
            },
            (error) => {
              reject(error);
            }
          );
        } else {
          resolve(result);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

function setCustomer(attributes) {
  let submitData = {};

  submitData = {
    OrganizationName: attributes.name,
    BirthDate: attributes.birthDate,
    EmailAddress: attributes.emailAddress,
    MobileTelephone1: attributes.telephone,
    FederalTaxId:
      attributes.taxNumber && attributes.taxNumber.toUpperCase().startsWith("PT")
        ? attributes.taxNumber.toUpperCase().replace("PT", "")
        : attributes.taxNumber,
    CardId: attributes.cardId,
    AddressLine1: attributes.address,
    PostalCode: attributes.postalCode,
    PostalCodeName: attributes.city,
    ISOCountryId: attributes.country ? attributes.country.value : null,
  };

  return apiPost("pos/customers/", submitData);
}

//TODO: alterar o nome da função para getSaleAvailableCampaigns
// e alterar para o ficheiro sales.service.js
function getCustomerAvailableCampaigns(attributes) {
  let submitData = {};

  let cartItems = attributes.items;
  let totalValue = 0;
  let totalItems = 0;
  let saleItems = [];

  let currentdate = new Date();
  let month = "" + (currentdate.getMonth() + 1);
  let day = "" + currentdate.getDate();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  let datetime =
    currentdate.getFullYear() +
    "-" +
    month +
    "-" +
    day +
    " " +
    currentdate.getHours() +
    ":" +
    currentdate.getMinutes() +
    ":" +
    currentdate.getSeconds();

  for (const element of cartItems) {
    let taxIncluded = 0;

    let itemDiscount = 0;

    if (element.discount.type === "percentage") {
      taxIncluded = round2Decimals(element.item_unit_price * element.quantity * (1 - element.discount.value / 100));
      itemDiscount = round2Decimals(element.item_unit_price * element.quantity - taxIncluded);
    } else {
      taxIncluded = round2Decimals(element.item_unit_price * element.quantity - (element.discount.value || 0));
      itemDiscount = round2Decimals(element.discount.value || 0);
    }

    totalValue += taxIncluded;

    let saleItem = {
      item: element.item_id,
      quantity: element.quantity,
      item_unit_price: Math.ceil(element.item_unit_price * 100),
      sale_value: Math.ceil(element.item_unit_price * element.quantity * 100),
      discount_value: Math.ceil(itemDiscount * 100),
      is_offer: 0,
    };

    saleItems.push(saleItem);
  }

  submitData = {
    //@modified ana.castro 2022.10.21 SABO-2347
    // store: router.currentRoute.params.store,
    store_code: router.currentRoute.params.store,
    datetime: datetime,
    customer_count: 1,
    table_total: Math.ceil(totalValue * 100),
    number_of_items_in_table: totalItems,
    customer_card_id: attributes.loyaltyCardId,
    customer_tax_number: attributes.taxNumber,
    customer_mobile_telephone1: attributes.customerId,
    sale_details: saleItems,
  };

  return apiPost("pos/loyalty/sale/check/", submitData);
}
