import { getLocalDataBase } from "@/_helpers";
import Dexie from "dexie";
import router from "@/router";
import Vue from "vue";
import { dataLogServices } from "@/services";
// import { v1 as uuidv1 } from "uuid";

export const dataLog = {
  namespaced: true,
  actions: {
    // eslint-disable-next-line
    setData({ commit, dispatch }, { actionType, actionValue, actionData, actionIdentifier }) {
      // let registerIdentifier = undefined;
      // if (actionIdentifier) {
      //   registerIdentifier = actionIdentifier;
      // } else {
      //   registerIdentifier = Math.floor(Math.random() * 1000000000);
      // }

      //Open database and make it start functioning.
      let db = getLocalDataBase();
      db.open();

      const date = new Date();
      const timeZoneOptions = { timeZone: "Europe/Lisbon" };

      // Formatting the date
      const year = date.toLocaleString("pt-PT", { ...timeZoneOptions, year: "numeric" });
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.toLocaleString("pt-PT", { ...timeZoneOptions, day: "2-digit" });
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");
      const milliseconds = date.getMilliseconds().toString().padStart(2, "0");

      // Creating the formatted date string
      // eslint-disable-next-line
      const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}:${milliseconds}`;

      //Use Dexie.spawn() or Dexie.async() to enable a synchronous-like programming style.
      // eslint-disable-next-line
      var spawn = Dexie.spawn;
      // Interact With Database
      // eslint-disable-next-line require-yield
      // spawn(function* () {
      //   db.posDataLog.put({
      //     // id: registerIdentifier,
      //     //id: registerIdentifier,
      //     //date: new Date(Date.now()).toLocaleString("pt"),
      //     date: formattedDate,
      //     actionType: actionType,
      //     value: JSON.stringify(actionValue),
      //     data: JSON.stringify(actionData),
      //   });
      //
      //   // eslint-disable-next-line
      // }).catch(function (err) {
      //   // Catch any error event or exception
      //   console.error(err.stack || err);
      // });
    },
    //TODO
    async synchronizeDataLog({ dispatch }) {
      let db = getLocalDataBase();

      const date = new Date();
      const timeZoneOptions = { timeZone: "Europe/Lisbon" };

      // Formatting the date
      const year = date.toLocaleString("pt-PT", { ...timeZoneOptions, year: "numeric" });
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.toLocaleString("pt-PT", { ...timeZoneOptions, day: "2-digit" });
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const seconds = date.getSeconds().toString().padStart(2, "0");

      // Creating the formatted date string
      const currentDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      //let currentDate = new Date(Date.now()).toLocaleString("pt");

      let posDataLogs = await db.posDataLog.where("date").below(currentDate).toArray();
      //let posDataLogs = await db.posDataLog.where("date").below(currentDate).sortBy("date");

      let submitDataList = [];
      const chunkSize = 50;
      console.log("AKI???", posDataLogs.length);
      //Percorrer cada registo da tabela posLogData
      //Chamar o serviço datalog.setLog para sincronizar o registo
      //Apagar o registo se o endpoint do sincronismo não devolver erro
      for (let i = 0; i < posDataLogs.length; i++) {
        let submitData = {
          StoreId: router.currentRoute.params.store,
          Date: posDataLogs[i].date,
          ActionType: posDataLogs[i].actionType,
          Value: posDataLogs[i].value,
          ExtraInfo: JSON.stringify(posDataLogs[i].data),
        };
        submitDataList.push(submitData);
        console.log("i", i, posDataLogs.length);
        if (submitData.length !== 0 && (i % chunkSize === 0 || i + 1 === posDataLogs.length)) {
          await dataLogServices.setLog(submitDataList).then(
            // eslint-disable-next-line
            (result) => {
              console.log("ESTOU AKI====????");
              db.posDataLog
                .where("date")
                .below(posDataLogs[i].date)
                .delete()
                .then(function (deleteCount) {
                  console.log("Deleted " + deleteCount + " rows");
                })
                .catch(function (error) {
                  console.error("Error: " + error);
                });
            },
            (error) => {
              console.log("ESTOU AKI=ERRROOOO=????");
              Vue.$log.warn(error);
              i = posDataLogs.length + 1;
            }
          );
        }
      }

      //Setting the synchronization to run 3 minutes after the synchronization is finished
      setTimeout(function () {
        let autoSync = 1;
        let forceUpdate = 0;
        dispatch("synchronizeDataLog", { forceUpdate, autoSync });
      }, 180000);
    },
  },
  getters: {},
};
