// Acesso à secção de Relatórios
export const PERM_REPORTS = 101;
// Acesso à Devolução de Vendas
export const PERM_SALE_RETURN = 102;
// Acesso à Anulação de Vendas
export const PERM_SALE_CANCEL = 103;
// Acesso à funcionalidade de marcar uma Venda como Isenta de Iva
export const PERM_SALE_NO_VAT = 104;
// Acesso à interface de Administração/Configuração
export const PERM_ADMINISTRATION = 105;
//Acesso à funcionalidade de Criar uma venda a partir de uma Saída Justificada
export const PERM_SALE_FROM_STOCK_EXIT = 108;

export const verbosePermissions = {
  102: "Devoluções",
  103: "Anulações",
  104: "Definir Venda com Isenção de IVA",
  101: "Consulta de Relatórios",
  105: "Configurar e Sincronizar Entidades",
  108: "Criar Vendas a partir de Saídas Justificadas",
};
