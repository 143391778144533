"use strict";

import Vue from "vue";
import axios from "axios";
import { store } from "../store";
import { getApiBaseUrl, setAxiosDefaultHeaders } from "../_helpers";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let isRefreshing = false;
let refreshSubscribers = [];

let config = {
  baseURL: getApiBaseUrl(),
  withCredentials: false,
};

/**
 * The requests which fail while the Token is not refresh, must be stored on this list.
 * After the Token is successfully refreshed the requests stored on the list are evoked again with the new Token.
 * @param cb
 */
function subscribeTokenRefresh(cb) {
  Vue.$log.debug();
  refreshSubscribers.push(cb);
}

/**
 * This is the function called after receiving a new Access Token.
 * Using the new Token the failed requests are evoked again.
 * At the end, the requests stored on the list refreshSubscribers are cleaned.
 * @param token
 */
function onRefreshed(token) {
  Vue.$log.debug("Pending Requests List Size: ", refreshSubscribers.length);
  refreshSubscribers.map((cb) => cb(token));
  refreshSubscribers.length = 0;
}

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response errors
    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;

    // @modified ana.castro 2019.09.24 SCLAPP-20
    Vue.$log.debug("ERROR: REQUEST FAILED | Request: ", config);
    Vue.$log.debug("ERROR: REQUEST FAILED | Status: ", status);

    // An interceptor in place to catch 401 errors if the access token expires
    if (
      status === 401 &&
      !config["url"].endsWith("rest-auth/refresh_token/") &&
      !config["url"].endsWith("rest-auth/obtain_token/") &&
      !config["url"].endsWith("rest-auth/obtain-token/")
    ) {
      if (!isRefreshing) {
        isRefreshing = true;
        //  If the Token expires it tries the refresh token to get a new access token.
        //  If any other calls are made during this time they are queued (subscribeTokenRefresh)
        //  until the access token is validated.
        // Once the access token has been refreshed the queue of requests is processed
        store.dispatch("authentication/refreshToken").then((newToken) => {
          isRefreshing = false;
          onRefreshed(newToken);
        });
      }

      // eslint-disable-next-line
      const retryOrigReq = new Promise((resolve, reject) => {
        subscribeTokenRefresh((token) => {
          // Replace the expired token and retry
          originalRequest.headers["Authorization"] = "JWT " + token;
          resolve(axios(originalRequest));
        });
      });
      return retryOrigReq;
    } else {
      return Promise.reject(error);
    }
  }
);

Plugin.install = function (Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;

Vue.axios.defaults.headers["Accept-Language"] = "pt"; //process.env.VUE_APP_LANG
setAxiosDefaultHeaders();
