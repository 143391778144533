import { userService } from "../services";
import { setAxiosDefaultHeaders } from "../_helpers";
import router from "../router";
import Vue from "vue";

/**
 * This dictionary represents the User's information which may be stored on the Local Storage.
 * The value for each field may be defined using specific mutations.
 * These User's attributes are checked in several moments of the application.
 * For example: localStorage.getItem('user') or this.$store.state.authentication.user.username
 * @type {{firstName: null, lastName: null, username: null, }}
 */
const user_data = {
  username: null,
  firstName: null,
  lastName: null,
  access: null,
  refresh: null,
};

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user ? { status: { loggedIn: true }, user } : { status: {}, user: user_data };

export const authentication = {
  namespaced: true,
  state: initialState,
  actions: {
    /**
     * This function is called when a success answer is received from the backend, indicating the Login's process
     * had the expected behaviour.
     * The success answer has a flag which can have two different values and depending on this value, the following steps may differ:
     * 1 - It is a New User (result.info_user === "newUser"):
     * the user is redirected to the Profile interface where he can fill in his personal data;
     * the profile can be filled in with some values depending on what the Login Social Provider shared with us.
     * (this applies to a login process in a context of using a Social Login Provider)
     * 2 - It's an Existing User (result.info_user === "existingUser" or None):
     * sets the Store loggedIn status to True; sets the flags for mandatory changing password and
     * mandatory accepting RGPD according to the data received from backend;
     * the user is redirected to the Home interface where he can fill in his personal data
     */
    setInfoAfterLogin({ dispatch, commit }, { result }) {
      Vue.$log.debug("Backend successful answer to the Login process");
      Vue.$log.debug("Login Process Received Data:");
      Vue.$log.debug(JSON.stringify(result));

      commit("loginSuccess", result);

      // On Login must set the Authentication header
      setAxiosDefaultHeaders();

      // The user is redirected to the Home interface where he can fill in his personal data
      router.push({
        name: "home",
        params: {
          tenant: router.currentRoute.params.tenant,
          store: router.currentRoute.params.store,
        },
      });

      // Removing the Loading Layer
      dispatch("loader/setLoading", false, { root: true });
    },
    /**
     * The Login Function which uses the correct User Service depending on the login's type (password or social provider).
     * After calling the correct User Service and receiving the Server's answer,
     *  it analyses the response and sets a few flags according to it
     * @param dispatch
     * @param commit
     * @param method: password or oauth
     * @param params: {username, password and recaptcha token} OR {social provider access token}
     */
    loginGeneric({ dispatch, commit }, { method, params }) {
      //commit('loginRequest', {username});
      Vue.$log.debug("Login Generic | Method: " + method + " | Parameters: " + JSON.stringify(params));

      //Identifying the Login method based on the received parameter
      let loginMethod = undefined;
      if (method === "password") {
        let { username, password, token } = params;
        loginMethod = userService.login(username, password, token);
      } else {
        let { provider, code } = params;
        loginMethod = userService.loginSocialProvider(provider, code);
      }

      //Calling the User Service login method
      loginMethod.then(
        (result) => {
          if (result.status === 1) {
            dispatch("setInfoAfterLogin", { result });
          } else {
            // Removing the Loading Layer
            dispatch("loader/setLoading", false, { root: true });
            commit("loginFailure", result.error_description);
            dispatch("alert/error", "$vuetify.authentication.login_error", {
              root: true,
            });
          }
        },
        (error) => {
          // Removing the Loading Layer
          dispatch("loader/setLoading", false, { root: true });
          commit("loginFailure", error);
          if (error.response.status === 403) {
            dispatch("alert/error", "$vuetify.forbidden", {
              root: true,
            });
          } else {
            dispatch("alert/error", "$vuetify.authentication.login_error", {
              root: true,
            });
          }
        }
      );
    },
    /**
     * Login Function when the login method is password. This means the user indicates its username and password.
     * @param dispatch
     * @param commit
     * @param username
     * @param password
     * @param token
     */
    // eslint-disable-next-line
    login({ dispatch, commit }, { username, password, token }) {
      let method = "password";
      let params = { username, password, token };
      dispatch("loginGeneric", { method, params });
    },
    logout({ commit }) {
      userService.logout();
      // On LogOut must set the Authentication header to NULL
      setAxiosDefaultHeaders();
      commit("logout");
    },
    refreshToken({ dispatch, commit }) {
      // @modified ana.castro 2019.09.24 SCLAPP-20 It is mandatory to return a Promise
      return new Promise((resolve, reject) => {
        userService.refreshToken().then(
          (token) => {
            if (token) {
              commit("refreshSuccess");
              setAxiosDefaultHeaders();
              resolve(token);
            } else {
              commit("logout");
              // The user is redirected to the login interface
              router.push({ name: "login" });
              // Removing the Loading Layer
              dispatch("loader/setLoading", false, { root: true });
            }
          },
          (error) => {
            Vue.$log.warn(error);
            commit("logout");
            reject(error);
            // The user is redirected to the login interface
            router.push({ name: "login" });
            // Removing the Loading Layer
            dispatch("loader/setLoading", false, { root: true });
          }
        );
      });
    },
  },
  mutations: {
    setLoginUsername(state, username) {
      state.status = { loggingIn: false };
      state.user.username = username;
    },
    loginSuccess(state, user_data) {
      state.status = { loggedIn: true };
      state.user.username = user_data.username;
      state.user.firstName = user_data.firstName;
      state.user.lastName = user_data.lastName;
      state.user.access = user_data.access;
      state.user.refresh = user_data.refresh;
      state.user.tenant = router.currentRoute.params.tenant;
      state.user.store = router.currentRoute.params.store;
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    loginFailure(state) {
      state.status = {};
      state.user = user_data;
    },
    logout(state) {
      state.status = {};
      state.user = user_data;
    },
    refreshSuccess() {
      Vue.$log.debug("refreshSuccess");
    },
  },
};
