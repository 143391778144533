import { es } from "vuetify/lib/locale";

export default {
  ...es,

  languageName: "ES - Español",
  languageFlag: "es",

  //Erros
  errorCreditNotesIssue: "No more credit notes can be issued for the Invoice", //Não é possível emitir mais notas de crédito para a Fatura
  errorGettingCountries: "Error getting Countries", //"Erro a obter Países"
  errorGettingCustomerDetail: "Getting the Customer Detail",
  errorIdAvailableCampaigns: "Could not identify available campaigns", //Não foi possível identificar Campanhas Disponíveis
  errorIdAvailableVouchers: "No Available Vouchers could be identified.", //Não foi possível identificar Vales Disponíveis.
  errorInfoEmployee: "Error obtaining information from Employee ", //Erro a obter informação do Empregado
  errorInvalidLoyaltyCard: "Invalid Loyalty Card", //Cartão de Fidelização Inválido
  errorNoSaleItems: "There are no items in the Sale", //Não há artigos na Venda
  errorRegisterBudget: "It was not possible to register the budget.", //Não foi possível registar o Orçamento.
  errorRegisterClient: "It was not possible to register client.", //Não foi possível registar o Cliente.
  errorRegisterSale: "It was not possible to register sale.", //Não foi possível registar a venda.
  errorRegisterSessionMovement: "It was not possible to register the Session Movement.", //Não foi possível registar o Movimento de Sessão.
  errorSearchValueNotIndicated: "No value was indicated for search.", //Não foi indicado um valor para pesquisa.
  errorStoreZone: "Error on getting store zones", // Erro a obter Zonas da Loja
  errorValidatingCredentials: "It was not possible to validate the Employee and password indicated. ", //Não foi possível validar o Empregado e palavra-passe indicados.
  itemNotFoundMessage: "Erro ao Adicionar Artigo à Venda. Artigo não encontrado.",
  catalogSearchResults: {
    errorIdArticles: "It was not possible to identify articles according to the survey.", //Não foi possível identificar Artigos de acordo com a pesquisa.
    errorNoSearch: "No search value was indicated.", //Não foi indicado um valor para pesquisa.
  },
  errorStockExit: "Error al obtener la salida justificada.",
  notFoundStockExit: "Salida justificada no encontrada.",
  invalidItemStockExit: "Se encontraron artículos no sincronizados en la salida justificada.",
  errorCancelDocument: "No es posible cancelar el documento.",
  errorItemToReturn: "Los artículos no se pueden agregar a una devolución.",
  errorItemInfoExtra: "La venta sin Artículos no permite agregar artículos Informativos/Extra.",

  //Legendas, Botões e Opcões
  add: "Add", //Adicionar
  addressClient: "Address", //Endereço
  backoffice: "Backoffice",
  billingData: "Billing data", //Dados faturação
  campaigns: "Campanhas",
  cancel: "Cancel", //Cancelar
  cancelAndPrint: "Suspend & Print", //Suspender e Imprimir
  cancelDocument: "Anulação", //Anulação
  fromStockExit: "Venta por Salida Justificada",
  cashDrawers: "Cash Drawers", //Caixas
  catalog: "Catálogo",
  change: "Troco",
  clean: "Clean", //Limpar
  client: "Cliente",
  close: "Close", //Fechar
  code: "Code", //Código
  consultSales: "Consult sales", //Consulta de Vendas
  consultSalesDescription: "Consult emitted sales documents", //Consulte documentos de venda emitidos
  consultSuspended: "Consult suspended documents", //Consulte documentos em suspenso
  country: "País",
  customerLoyaltyCard: "Cartão Fidelização",
  customerMobileTelephone: "Nr. Telemóvel",
  customerSearchMessage: "Pesquise pelo Cartão de Fidelização, NIF ou Número de Telemóvel",
  date: "Data",
  discount: "Desconto",
  discountShort: "Disc.%", //Desc.%
  discountType: "Tipo Desconto",
  download: "Download",
  email: "E-mail",
  end: "Finish", //Finalizar
  finalClient: "Cliente final",
  globalDiscount: "Desconto Global",
  goBackToLogin: "Voltar para o login",
  identifier: "Identificador",
  location: "Localidade",
  logout: "LogOut",
  loyalty: "Campanhas Fidelização",
  loyaltyCardVoucherNumberMessage: "Cartão Fidelização/Nr. Vale",
  moneyRemaining: "Faltam",
  more: "Mais",
  name: "Nome",
  newClient: "Novo Cliente",
  numberOfCopies: "Quantidade Impressão",
  obs: "Observações",
  offerDocument: "Talão Oferta", //Talão Oferta
  ok: "Ok", //Ok
  open: "Abrir",
  openCashDrawer: "Open drawer", //Abrir Caixa
  password: "Palavra-passe",
  pattyCash: "Fundo de Maneio",
  pay: "Pagar", //
  payment: "Pagamento", //
  paymentMethod: "Método de Pagamento", //
  paymentMethods: "Formas de Pagamento", //
  percentage: "Percentagem",
  points: "Pontos",
  postalCode: "Código Postal", //
  print: "Imprimir", //Imprimir
  quantity: "Quantidade",
  reports: "Relatórios",
  resumeSale: "Retomar",
  return: "Return", //Devolver
  returnDocument: "Devolução", //Devolução
  sale: "Sale", //Venda
  saleDocumentDate: "Data Documento",
  sales: "Sales", //Vendas
  salesDescription: "Crie novos documentos de venda", //
  salesmenName: "Atendido por", //
  salesmenSessionClose: "Fechar Sessão", //
  salesmenSessionOpen: "Abrir Sessão", //
  salesSub: "Visão dos totais de vendas, totais de IVA e formas de pagamento", //
  save: "Guardar", //
  savedSales: "Sem Vendas Suspensas", //
  search: "Pesquisar", //
  searchDocument: "Pesquisar Nr. Documento", //
  selectDate: "Selecionar Data",
  selectSalesman: "Selecionar Empregado",
  sessions: "Sessões", //
  sessionsProcedure: "Proceda à abertura e fecho de sessões", //
  start: "Início",
  submit: "Submeter", //
  suspendedSales: "Vendas Suspensas", //
  taxNumber: "NIF",
  total: "Total", //
  unitPrice: "Preço Unitário", //
  updatePassword: "Atualizar Password",
  validate: "Validate", //
  valuePay: "Valor", //
  voucherCode: "Código do Vale",

  //Mensagens de Feedback e Confirmação
  appliedVatExempt: "IVA Isento Aplicado", //
  applyVatExempt: "Aplicar Isenção IVA", //
  cashDrawersProcedure: "Proceda à Abertura e Fecho de Caixas", //
  confirmConfirmCancelDocumentMessage: "Esta é uma operação irreversível.",
  confirmConfirmCancelDocumentTitle: "Tem a certeza que pretende Anular a Venda?",
  confirmLogout: "Encerrar a Sessão?",
  msgAreYouSure: "Tem a certeza?", //
  msgAreYouSureRemoveItems: "Tem a certeza que pretende remover todos os Artigos da Venda?", //
  msgAreYouSureSuspendSale: "Tem a certeza que pretende Suspender a Venda?", //
  msgCheckInstructions: "Verifique as instruções no terminal de pagamento", //
  msgCloseSessionSuccess: "Fecho de Sessão realizada com Sucesso.", //
  msgContentNotAvailable: "Conteúdo não disponível de momento.", //
  msgDetailsInvoice:
    "Introduza os seus dados se pretender fatura com dados fiscais ou clique em Pagamento para avançar", //
  msgExpiredVoucher: "O Talão de Reembolso expirou em ", //
  msgInvalidSlip: "Identificador do Talão de Reembolso inválido.", //
  msgOpenEmployeeSessions: "É necessário proceder à abertura de Sessões de Empregados", //
  msgOpenSessionSuccess: "Abertura de Sessão realizada com Sucesso.", //
  msgSuspendSaleAlert: "Quer suspender esta venda? Se não suspender, perderá os artigos da venda!", //
  msgUsedVoucher: "O Talão de Reembolso já foi utilizado.", //
  msgCancelDocument: "Cancelación del Documento realizada con éxito.",
  pageNotFound: "Página não encontrada", //
  removeVatExempt: "Remover Inseção IVA", //
  startPurchase: "Inicie a sua compra", //
  tryAgain: "Por favor tente novamente.", //
  warningNoPermissionsTo: "Sem Permissões para aceder a '", //
  welcome: "Bem-Vindo", //
  cashDrawersPage: {
    activateSalesBox: "Ativar Caixa de Venda", //
    checkCashMov: "Consulta de Movimentos de Caixa", //
    closeCashRegister: "Fecho de caixa", //
    consult: "Consultar", //
    getData: "Get Cash Drawer Data",
    includesPattyCash: "Valor do Numerário inclui Fundo de Maneio?", //
    initialPattyCash: "Fundo de maneio inicial", //
    msgCloseSuccess: "Fecho de Caixa realizado com Sucesso", //
    msgMovemNotAvailable: "Não foi possível registar o Movimento de Caixa.", //
    msgOpenClose: "É necessário proceder à abertura/selecção de Caixa antes de realizar qualquer operação", //
    msgOpenSuccess: "Abertura de Caixa realizada com Sucesso", //
    openCashRegister: "Abertura de caixa", //
    pattyCashNextDay: "Fundo Maneio Próx. Dia", //
  },
  cashDrawersSearchPage: {
    gettingResults: "A obter resultados de pesquisa",
  },
  noResults: "No hay resultados",

  //Validações
  addressMax200: "Endereço deve ter no máximo 200 caracteres", //
  countryMax2: "País deve ter no máximo 2 caracteres", //
  errorInvalidEmail: "E-mail Inválido", //
  errorInvalidMobile: "Nr. Telemóvel inválido", //
  errorInvalidTIN9: "NIF deve ter 9 dígitos", //
  errorInvalidTIN: "NIF Inválido", //
  expiredPassword: "Palavra-passe Expirada",
  expiredPasswordMessage: "É necessário proceder à alteração da sua palavra-passe.",
  firstAndLastName: "Primeiro e Último Nome", //
  locationMax100: "Localidade deve ter no máximo 100 caracteres", //
  mandatoryField: "Campo obrigatório", //
  nameMax20: "Nome deve ter no máximo 20 caracteres", //
  noStock: "Sem stock", //
  passwordRequired: "Palavra-passe requerida",
  paymentMethodDoesNotAllowChange: "O Método de pagamento não permite troco", //
  postalCodeMax20: "Cód. Postal deve ter no máximo 20 caracteres", //
  usernameRequired: "Nome de Utilizador requerido",
  value100: "Valor deve começar por 100", //
  value10to20: "Valor deve ter entre 10 e 20 caracteres", //
  valueAbove0: "Valor deve ser igual ou superior a 0", //
  valuesBetween0to100: "Valores válidos: entre 0 e 100", //
  valuesBetween0toX: "Valores válidos: entre 0 e ", //
  itemsMenuLevelsCardPage: {
    allowed: "Apenas são permitidos", //
    itemsInLevel: "artigos no nível", //
    maxChoices: "Nr. Máximo de Escolhas", //
    summary: "Resumo", //
  },

  //Páginas específicas:
  //--Página de Administração
  admin: {
    administration: "Administração",
    //1º cartão
    deviceConfiguration: "Configuração Dispositivos",
    printer: "Impressora",
    scale: "Balança",
    cashDrawer: "Caixa",
    virtualKeyboard: "Teclado virtual",
    //2º cartão
    generalSettings: "Configurações gerais",
    productDescription: "Descrição dos Artigos",
    employeeSelection: "Obrigatório selecionar Empregado",
    sizePrintDoc: "Tamanho Impressão Documentos Venda",
    receiptRoll: "Rolo",
    a4: "A4",
    short: "Curta",
    complete: "Completa",
    useLocalTime: "Utilice la hora local al emitir facturas",
    //3º cartão
    dataSynchronization: "Sincronizar informação",
    updateMessage: "Selecione as entidades para atualizar com a informação mais recente disponível.",
    //options
    families: "Famílias",
    product: "Artigos",
    cashier: "Empregados",
    cashRegister: "Caixas",
    infoNotPossible: "Não foi possível obter a informação de ",
    syncMessageSuccess: "Sincronização de Informação realizada com Sucesso.",
  },

  authentication: {
    login_error: "No se pudo validar el nombre de usuario y la contraseña especificados. Inténtalo de nuevo.",
    forbidden: "Sin permiso",
  },
};
