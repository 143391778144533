import { apiFetch, getApiBaseStore, getApiParamsString } from "@/_helpers";

export const docTypesServices = {
  getAll,
};

async function getAll(synccounter) {
  let docTypes = [];

  let result = [];
  let i = 1;
  let fetchPath = "pos/doctypes/?store=" + getApiBaseStore();

  if (synccounter) {
    var params = {
      synccounter: synccounter,
    };

    fetchPath += "&" + getApiParamsString(params);
  }

  do {
    result = await apiFetch(fetchPath + "&page=" + i);
    docTypes = docTypes.concat(result.results);
    i += 1;
  } while (result.next && i < 2000);

  return docTypes;
}
